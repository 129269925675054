import store from '../store';

export default function auth({ next, router }) {
  //console.log('auth mid');
  if (!store.getters.isAuthenticated) {
    return router.push({ name: 'Login' });
  }
  // if (store.getters.isAuthenticated) {
  //   return router.push({ name: "Home" });
  // }

  return next();
}

import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/approverlist`, options);
  return response.data;
}

async function saveapproveall_onerow(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  const response = await axios.get(`${url}/v1/saveapproveall_onerow`, options);
  return response.data;
}

async function saveapproveall(params) {
  const response = await axios.post(`${url}/v1/saveapproveall`, params);
  return response.data;
}

async function saveapproveall_flat_rate(params) {
  const response = await axios.post(
    `${url}/v1/saveapproveall_flat_rate`,
    params,
  );
  return response.data;
}

async function saveapproveall_otoverlimit(params) {
  const response = await axios.post(
    `${url}/v1/saveapproveall_otoverlimit`,
    params,
  );
  return response.data;
}

async function get_approve_flat_rate(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  const response = await axios.get(`${url}/v1/get_approve_flat_rate`, options);
  return response.data;
}

async function get_approve_all(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  const response = await axios.get(`${url}/v1/get_approve_all`, options);
  return response.data;
}

async function getlastapproveotlimit(params) {
  const response = await axios.post(`${url}/v1/getlastapproveotlimit`, params);
  return response.data;
}

async function get_ot(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  const response = await axios.get(`${url}/v1/get_ot`, options);
  return response.data;
}

async function getPending(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  const response = await axios.get(`${url}/v1/getpendingtotal`, options);
  return response.data;
}

export default {
  list,
  saveapproveall_onerow,
  saveapproveall,
  saveapproveall_otoverlimit,
  get_approve_all,
  getlastapproveotlimit,
  get_ot,
  getPending,
  get_approve_flat_rate,
  saveapproveall_flat_rate,
};

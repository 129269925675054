import service from '@/store/services/approvers-service';

const state = {
  total: 0,
};

const mutations = {
  SET_TOTAL: (state, total) => {
    state.total = total;
  },
};

const actions = {
  async list({ commit, dispatch }, params) {
    return await service.list(params);
  },

  async saveapproveall_onerow({ commit, dispatch }, params) {
    return await service.saveapproveall_onerow(params);
  },

  async saveapproveall({ commit, dispatch }, params) {
    return await service.saveapproveall(params);
  },

  async saveapproveall_flat_rate({ commit, dispatch }, params) {
    return await service.saveapproveall_flat_rate(params);
  },

  async saveapproveall_otoverlimit({ commit, dispatch }, params) {
    return await service.saveapproveall_otoverlimit(params);
  },

  async get_approve_flat_rate({ commit, dispatch }, params) {
    return await service.get_approve_flat_rate(params);
  },

  async get_approve_all({ commit, dispatch }, params) {
    return await service.get_approve_all(params);
  },

  async getlastapproveotlimit({ commit, dispatch }, params) {
    return await service.getlastapproveotlimit(params);
  },

  async get_ot({ commit, dispatch }, params) {
    return await service.get_ot(params);
  },

  async getPending({ commit, dispatch }, params) {
    return await service.getPending(params);
  },

  settotal({ commit, dispatch }, value) {
    commit('SET_TOTAL', value);
    return value;
  },
};

const getters = {
  total: (state) => state.total,
  //listTotal: (state) => state.meta.page.total,
};

const approvers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default approvers;

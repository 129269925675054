<template>
  <div>
    <vue-progress-bar></vue-progress-bar>
    <user-edit-card
      :user="user"
      :editProfile_show="editProfile_show"
      @setImagePath="setPathImg"
      @init="reloadEditProfile"
      @beginUpImg="startUpImg"
      @showEdit="showEdit"
    />
    <div v-if="user" class="md-layout md-gutter md-alignment-center">
      <div
        class="md-layout-item md-size-30 md-large-size-35 md-medium-size-45 md-small-size-50 md-xsmall-size-90"
      >
        <user-profile-card
          :user="user"
          :cardUserImage="profile_image"
          @init="reloadUserProfile"
          @openEdit="showEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserEditCard from '@/pages/Dashboard/Pages/Profile/UserProfile/EditProfileCard.vue';
import UserProfileCard from '@/pages/Dashboard/Pages/Profile/UserProfile/UserProfileCard.vue';

export default {
  name: 'user-profile',

  components: {
    'user-profile-card': UserProfileCard,
    'user-edit-card': UserEditCard,
  },

  data: () => ({
    user: null,
    profile_image: '',
    userProfile_created: false,
    editProfile_created: false,
    editProfile_show: false,
  }),

  created() {
    this.getProfile();
  },

  methods: {
    async getProfile() {
      this.start();
      this.user = await this.$store.getters['profile/me'];
      if (this.user == null) {
        await this.$store.dispatch('profile/me').then(
          async (response) => {
            this.user = await this.$store.getters['profile/me'];
            this.finish();
          },
          (response) => {
            this.fail();
          },
        );
      }

      if (this.user.profile_image != '') {
        this.profile_image =
          process.env.VUE_APP_IMAGE_BASE_URL + this.user.profile_image;
      }
    },

    setPathImg(imagepath) {
      this.profile_image = process.env.VUE_APP_IMAGE_BASE_URL + imagepath;
    },
    reloadEditProfile(v) {
      this.editProfile_created = v;
      this.childCompleteRencer();
    },
    reloadUserProfile(v) {
      this.userProfile_created = v;
      this.childCompleteRencer();
    },

    childCompleteRencer() {
      if (this.editProfile_created && this.userProfile_created) {
        this.finish();
      }
    },
    startUpImg(v) {
      if (!v) {
        this.start();
      } else {
        this.finish();
      }
    },
    showEdit(v) {
      this.editProfile_show = v;
    },
    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>

<template>
  <div class="box-appv">
    <md-dialog
      :md-active.sync="showPrivate"
      :md-fullscreen="false"
      style="max-height: 300px; max-width: 500px; min-height: 25%; min-width: 30%;"
    >
      <md-dialog-title align="center" style="font-size:1.8em">
        Private Code ?
      </md-dialog-title>
      <md-content align="center">
        <input-private
          @resultPrivateCode="resultPrivateCode($event)"
          ref="inputprivate"
        ></input-private>
      </md-content>
      <md-dialog-actions class="center">
        <md-button class="md-button md-success" @click="securePrivatecode()">
          Yes, Approve it!
        </md-button>
        <md-button class="md-button md-danger" @click="showPrivate = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <b-container>
      <b-row>
        <b-col
          md="6"
          sm="12"
          v-for="item_persons in persons"
          :key="item_persons.id"
          :value="item_persons.id"
        >
          <div class="box-appv-control">
            <div class="box-appv-person">
              <strong>
                {{ item_persons.firstname_th }} {{ item_persons.lastname_th }}
                {{ item_persons.position_detail }}
              </strong>
              <div>
                <a v-if="item_persons.status == 'approved'">
                  <md-icon class="approve">
                    check_circle
                  </md-icon>
                </a>
                <div v-if="item_persons.status == 'pending'">
                  <a
                    class="pointer"
                    @click="showInputPrivateCode(item_persons.approver_id)"
                    v-if="item_persons.emp_id == profile.emp_id"
                  >
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                  <a v-else>
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                </div>
                <a v-else-if="item_persons.status == 'disabled'"></a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import md5 from 'js-md5';
import InputPrivateCode from '@/pages/Dashboard/Components/InputPrivateCode';
// import SignaturePad from 'signature_pad';
export default {
  name: 'BoxAppved',
  components: {
    'input-private': InputPrivateCode,
  },
  data() {
    return {
      showPrivate: false,
      approver_id: null,
    };
  },
  created() {},
  updated: () => {},
  mounted: () => {},
  props: {
    profile: {
      type: Object,
    },
    report_select: {
      type: Object,
    },
    persons: {
      type: Array,
    },
    branchs: {
      type: Number,
      default: null,
    },
    departments: {
      type: Number,
      default: null,
    },
  },
  computed: {},
  watch: {},
  methods: {
    showInputPrivateCode(approver_id) {
      this.showPrivate = !this.showPrivate;
      this.approver_id = approver_id;
    },
    async resultPrivateCode(res) {
      // console.log(res);
      if (res != 'empty') {
        if (res == true) {
          // console.log('approve');
          this.salary_accept(this.approver_id);
        } else {
          // console.log('invalid');
          Swal.fire('กรุณากรอก Private Code ให้ถูกต้อง!', '', 'warning');
        }
        this.showPrivate = false;
      }
    },
    async salary_accept(approve) {
      if (approve == null) {
        this.$store.dispatch(
          'alerts/error',
          'มีบางอย่างผิดพลาด! : approver_id',
        );
      }
      let params = {
        ...{
          approver_id: approve ? approve : {},
        },
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
        ...{ from_date: this.report_select.from_date },
        ...{ to_date: this.report_select.to_date },
        // ...{ stafftype: this.report_select.stafftype },
      };
      this.$store.dispatch('salarys/approveReport', params).then(
        async (data) => {
          Swal.fire('Approve!', '', 'success');
          this.$emit('callback');
        },
        (err) => {
          Swal.fire('Error!', '', 'error');
        },
      );
      // const confirmation = await Swal.fire({
      //   title: 'Private code ?',
      //   input: 'password',
      //   inputLabel: 'อนุมัติ',
      //   inputPlaceholder: 'Enter Private Code',
      //   inputAttributes: {
      //     max: 20,
      //   },
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes, Approve!',
      //   cancelButtonText: 'No',
      //   customClass: {
      //     confirmButton: 'md-button md-success',
      //     cancelButton: 'md-button md-danger',
      //   },
      //   preConfirm: () => {
      //     if (!$('#swal-input-privatecode').val()) {
      //       Swal.showValidationMessage('กรุณากรอก Private Code!');
      //     }
      //     return new Promise((resolve) => {
      //       resolve([true, $('#swal-input-privatecode').val()]);
      //     });
      //   },
      // });
    },
    async securePrivatecode() {
      await this.$refs.inputprivate.accept();
    },
  },
};
</script>
<style lang="scss">
.approve {
  font-size: 35px !important;
  color: rgb(0, 160, 0) !important;
}
.pending {
  font-size: 35px !important;
  color: #feba12 !important;
}
.box-appv-person {
  font-weight: bold;
  font-size: 16.5px;
  font-family: 'Kanit';
  text-align: center;
  padding: 0 1.5em;
  display: inline-block;
  width: 100%;
  margin-top: 0.5em;
}

.box-appv-control {
  margin-top: 2em;
}

.pointer {
  cursor: pointer;
}

.center {
  justify-content: center;
  align-items: center;
}
</style>

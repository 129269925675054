import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

async function saveMemo(params) {
  const response = await axios.get(`${url}/v1/getInfoMemoDetail`);
  return response.data;
}
async function getMemoList(params) {
  const response = await axios.get(`${url}/v1/getmemolist`, { params });
  return response.data;
}

export default {
  getMemoList,
  saveMemo,
};

<template>
  <div class="md-layout spacing">
    <div class="md-layout-item md-size-100">
      <div class="md-theme-default">
        <md-card-content>
          <b-container fluid>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-align-sm="left"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="my-1 text-right"
              >
                <!-- <b-form-group
                  id="input-group-3"
                  label="เลือกจำนวน OT"
                  label-for="input-3"
                >
                  <b-form-select v-model="selected_sel_ot" :options="options">
                  </b-form-select>
                  <span style="color:red;" class="text-right">
                    ** สำหรับกรณีขออนุมัติทำงานล่วงเวลาเท่านั้น **
                  </span>
                </b-form-group> -->
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col xs="12" sm="6" lg="6" class="my-1">
                <b-button variant="primary" @click="selectAllRows">
                  เลือกทั้งหมด
                </b-button>
                <b-button variant="danger" @click="clearSelected">
                  ล้าง
                </b-button>
              </b-col>
              <b-col xs="12" sm="6" lg="6" class="my-1 text-right">
                <b-button variant="success" @click="approve">
                  Approve
                </b-button>
                <b-button variant="danger" @click="disapprove">
                  Disapprove
                </b-button>
              </b-col>
            </b-row>
          </b-container>
          <div class="approve-all-tbl">
            <b-table
              id="my-table"
              :items="items"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :select-mode="selectMode"
              :busy="isBusy"
              ref="selectableTable"
              selectable
              show-empty
              small
              responsive
              hover
              @row-selected="onRowSelected"
            >
              <template #empty>
                <center><h4>ไม่มีข้อมูล</h4></center>
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(detail)="row">
                <div style="text-align: center;">
                  <b>{{ row.item.detail.type_name }}</b>
                </div>
                <div style="text-align: left;">
                  <b>จาก : </b>{{ row.item.detail.from_date }}
                </div>
                <div style="text-align: left;">
                  <b>
                    ถึง
                    <span
                      v-if="
                        row.item.detail.type == 'Employeeleave' &&
                          row.item.detail.type
                      "
                    >
                      (เปลี่ยนเป็น)
                    </span>
                    :
                  </b>
                  {{ row.item.detail.to_date }}
                  <span v-if="row.item.detail.type == 'Employeeleave'">
                    - {{ row.item.detail.from_date_type_fmt }}
                  </span>
                  <span v-else-if="row.item.detail.type == 'Keepdays_off'">
                    - {{ row.item.detail.keep_type_fmt }}
                  </span>
                </div>
                <div
                  style="text-align: left;"
                  v-if="row.item.detail.type == 'Employeeot'"
                >
                  <b>ชั่วโมง : </b>{{ row.item.detail.ot_hours }}
                </div>
                <div
                  style="text-align: left;"
                  v-if="
                    row.item.detail.amount != '' && row.item.detail.ot_id == '4'
                  "
                >
                  <b>จำนวนเที่ยวเรือ : </b>{{ row.item.detail.amount }}
                </div>
                <div
                  style="text-align: left;"
                  v-if="
                    row.item.detail.amount != '' && row.item.detail.ot_id == '5'
                  "
                >
                  <b>ชั่วโมง : </b>{{ row.item.detail.amount }}
                </div>
                <div
                  style="text-align: left;"
                  v-if="
                    typeof row.item.detail.ot_cost != 'undefined' &&
                      row.item.detail.ot_cost != ''
                  "
                >
                  <b>ค่าแรง : </b>{{ row.item.detail.ot_cost }}
                </div>
                <div style="text-align: left;" v-if="row.item.detail.notes">
                  <b>หมายเหตุ : </b>{{ row.item.detail.notes }}
                </div>
                <b-button
                  variant="outline-primary"
                  v-if="
                    row.item.detail.file != '' && row.item.detail.file != null
                  "
                  @click="show_pic(row.item.detail)"
                >
                  File
                </b-button>
              </template>
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
            <b-container fluid>
              <b-row>
                <!-- <b-col sm="6" md="3" lg="3" class="my-1" align-v-self="end">
                    </b-col>
                    <b-col sm="6" md="3" lg="3" class="my-1" align-v-self="end">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col> -->
              </b-row>
            </b-container>
          </div>
        </md-card-content>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs } from '@/components';
import Swal from 'sweetalert2';
import moment from 'moment';
import Vuex from 'vuex';
import $ from 'jquery';
import { loading_start, loading_close } from '@/utils/loading.js';

// import { stringify } from 'querystring';
export default {
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL,
      isBusy: false,
      selected: [],
      // selected_sel_ot: '2',
      selectMode: 'multi',
      approve_st: 'approve',
      fields: [
        {
          key: 'selected',
          label: 'เลือก',
          tdClass: 'td-selected-approve',
          class: 'text-center',
        },
        {
          key: 'emp_id',
          label: 'รหัสพนักงาน',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'fullname_en',
          label: 'ชื่อพนักงาน',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'pos_nameen',
          label: 'ตำแหน่ง',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: true,
          class: 'text-center',
        },
      ],
      // totalRows: 0,
      // currentPage: 1,
      // perPage: 5,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      remark: '',
    };
  },
  async created() {},
  props: {
    totalRows: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    show_pic(data) {
      var file_name = data.file;
      if (file_name.toLowerCase().indexOf('data:application/pdf;') >= 0) {
        const linkSource = `${file_name}`;
        const downloadLink = document.createElement('a');
        const fileName = data.type_name + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.target = '_blank';
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        Swal.fire({
          html: `<img class="show-file" src="${file_name}">`,
          showCloseButton: false,
          showCancelButton: false,
        });
      }
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    onRowSelected(items) {
      this.selected = items;
    },

    async approve() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to approve the changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        confirmButtonText: 'Approve',
      }).then(async (result) => {
        if (result.value) {
          this.approve_st = 'approve';
          await this.save_approve();
        } else {
          return;
        }
      });
    },

    async disapprove() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to disapprove the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Disapprove',
      }).then(async (result) => {
        if (result.value || result.value == '') {
          this.remark = result.value;
          this.approve_st = 'disapprove';
          await this.save_approve();
        } else {
          return;
        }
      });
    },

    async save_approve() {
      let item = this.selected;
      let arr = [];
      let obj = {};
      var arr_id = [];

      $.each(item, function(i, v) {
        arr_id.push(v['row_id']);
      });

      arr['id'] = arr_id;

      obj = {
        id: arr['id'],
        status: this.approve_st,
        // ot_id: this.selected_sel_ot,
        remark: this.remark,
      };

      loading_start();

      let res = await this.$store.dispatch('approvers/saveapproveall', obj);
      let re = await this.$store.dispatch(
        'approvers/saveapproveall_otoverlimit',
        obj,
      );

      this.$emit('get_data');

      if (res.st == 'success' || re.st == 'success') {
        Swal.fire('Saved!', '', 'success');
      } else if (res.st == 'error' || re.st == 'error') {
        Swal.fire('Error!', '', 'error');
      }

      loading_close();
      return res;
    },
  },
};
</script>

<style>
/*
.approve-all-tbl {
  max-height: 400px;
}
*/
.td-selected-approve {
  width: 12% !important;
  vertical-align: middle !important;
  text-align: center;
  font-size: 2rem;
}
.show-file {
  width: 100%;
  height: 100%;
}
.footer-btn {
  width: 100%;
  text-align: right;
  margin: 10px 0;
  padding: 0px 50px;
}
</style>

import service from '@/store/services/profile-service';

const state = {
  me: null,
  mepermissions: null,
  checkget_me: 0,
};

var timeoutget_me = null;

const mutations = {
  SET_RESOURCE: (state, me) => {
    state.me = me;
  },
  SET_MEPERMIS: (state, mepermis) => {
    state.mepermissions = mepermis;
  },
};

const actions = {
  me({ commit, dispatch }, params) {
    if (state.me != null && state.checkget_me == 1) {
      return;
    }
    return service.get(params).then((profile) => {
      clearTimeout(timeoutget_me);
      commit('SET_RESOURCE', profile.list);
      state.checkget_me = 1;
      timeoutget_me = setTimeout(() => {
        state.checkget_me = 0;
      }, 10000);
    });
  },

  setmepermis({ commit, dispatch }, value) {
    commit('SET_MEPERMIS', value);
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((profile) => {
      commit('SET_RESOURCE', profile);
    });
  },
};

const getters = {
  me: (state) => state.me,
  mepermissions: (state) => state.mepermissions,
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default profile;

<template>
  <div class="createMemo">
    <md-card v-if="true">
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>add_circle_outline</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter md-alignment-center-right">
          <b-button variant="info" @click="previewPDF">Preview</b-button>
        </div>
        <div class="md-layout md-gutter md-alignment-center-center">
          <div class="md-layout-item md-size-30">
            <md-field>
              <label>สาขา</label>
              <md-select v-model="branch_id">
                <md-option
                  v-for="(item, index) in branchList"
                  :key="index + '-' + item.bch_id"
                  :value="item.bch_id"
                >
                  {{ item.brname_th }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <center>
              <img
                v-show="showLogo"
                :src="branchLogo[branch_id]"
                style="width:auto; height: 100px;"
              />
            </center>
          </div>
          <div class="md-layout-item md-size-100">
            <center><span class="headMemo">MEMORANDAM</span></center>
          </div>
          <div class="md-layout-item md-size-100">
            <center>
              <b-table-simple class="memo-table">
                <b-thead>
                  <b-tr>
                    <b-td>
                      <p>TO:</p>
                      <b-input
                        class="b-input-memo-table"
                        v-model="toText"
                      ></b-input>
                    </b-td>
                    <b-td>
                      <p>DATE:</p>
                      <b-datepicker
                        class="b-datepicker-memo"
                        v-model="dateMemo"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                        }"
                      ></b-datepicker>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <p>FROM:</p>
                      <b-input class="b-input-memo-table" v-model="fromText" />
                    </b-td>
                    <b-td>
                      <p>PAGE:</p>
                      <b-input class="b-input-memo-table" v-model="pageText" />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <p>SUBJECT:</p>
                      <b-input
                        class="b-input-memo-table"
                        v-model="subjectText"
                      />
                    </b-td>
                    <b-td>
                      <p>CC:</p>
                      <!-- <b-input class="b-input-memo-table" v-model="cc"></b-input> -->
                      <b-input class="b-input-memo-table" v-model="cc" />
                    </b-td>
                  </b-tr>
                </b-thead>
              </b-table-simple>
            </center>
          </div>
          <div class="md-layout-item md-size-100" v-if="check_showeditor">
            <hr />
            <!-- <center> -->
            <PdfmeEditor
              v-model="pdfme_template"
              ref="pdfmeEditor"
              :content="pdfme_template"
              :branchLogo="branchLogo[this.branch_id]"
              :memorandam="memorandam"
              @getPdfTemplate="getPdfTemplate($event)"
            >
            </PdfmeEditor>
            <!-- <QuillEditor
                v-model="content[index]"
                :content="content[index]"
                @getContent="getContent($event, index)"
                style="height: 150mm; width: 210mm;"
              ></QuillEditor> -->
            <!-- </center> -->
            <div class="md-layout-item md-size-100">
              <hr />
              อนุมัติโดย
              <b-button variant="info" @click="addApproverMemo()">
                เพิ่มผู้อนุมัติ
              </b-button>
              <div
                v-for="(item, index) in approverMemo"
                :key="index + 'approver'"
                class="md-layout md-layout-item md-alignment-center-left md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
              >
                <div
                  class="md-layout-item md-xlarge-size-80 md-large-size-70 md-medium-size-80 md-small-size-80 md-xsmall-size-100"
                >
                  {{ index + 1 }}.
                  <filter-employee
                    @childStaffs="setStaffsAdd($event, index, 'approver')"
                    :permissionsList="permissions"
                  />
                </div>
                <div
                  class="md-layout-item spacing md-xlarge-size-20 md-large-size-30 md-medium-size-20 md-small-size-20 md-xsmall-size-100"
                >
                  <b-button
                    variant="danger"
                    @click="deleteAppMemo(index, 'approver')"
                  >
                    ลบ
                  </b-button>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100">
              <hr />
              รับทราบโดย
              <b-button variant="info" @click="addAcknowledgeMemo()">
                เพิ่มผู้รับทราบ
              </b-button>
              <div
                v-for="(item, index) in acknowledgeMemo"
                :key="index + 'acknowledge'"
                class="md-layout md-layout-item md-alignment-center-left md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
              >
                <div
                  class="md-layout-item md-xlarge-size-80 md-large-size-70 md-medium-size-80 md-small-size-80 md-xsmall-size-100"
                >
                  {{ index + 1 }}.
                  <filter-employee
                    @childStaffs="setStaffsAdd($event, index, 'acknowledge')"
                    :permissionsList="permissions"
                  />
                </div>
                <div
                  class="md-layout-item spacing md-xlarge-size-20 md-large-size-30 md-medium-size-20 md-small-size-20 md-xsmall-size-100"
                >
                  <b-button
                    variant="danger"
                    @click="deleteAppMemo(index, 'acknowledge')"
                  >
                    ลบ
                  </b-button>
                </div>
              </div>
            </div>
            <div class="md-layout-item text-right">
              <b-button variant="success" @click="saveMemo">Save Memo</b-button>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <div v-else>
      กำลังแก้ไข
    </div>
  </div>
</template>
<script>
import PdfmeEditor from '@/components/RichEditor_Pdfme';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
import Vuex from 'vuex';

export default {
  components: {
    'filter-employee': FilterEmployee,
    'PdfmeEditor': PdfmeEditor,
  },
  async created() {
    this.branchList = await this.getBranchList();
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          per.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
    check_showeditor() {
      return this.set_showeditor();
    },
    memorandam() {
      const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      };

      const date = new Intl.DateTimeFormat('en-US', options).format(
        new Date(this.dateMemo),
      );

      return {
        toText: this.toText,
        fromText: this.fromText,
        dateMemo: date,
        pageText: this.pageText,
        subjectText: this.subjectText,
        cc: this.cc,
      };
    },
  },
  data() {
    return {
      approverMemo: [{}],
      acknowledgeMemo: [{}],
      dateMemo: '',
      toText: '',
      fromText: '',
      pageText: '',
      subjectText: '',
      cc: '',
      pdfme_template: null,
      showLogo: false,
      branchLogo: {},
      branch_id: null,
      brname_en: null,
      branchList: null,
      showeditor: false,
      logo: null,
    };
  },
  watch: {
    async branch_id() {
      this.showLogo = false;
      await this.getBranchLogo();
      this.showLogo = true;
    },
  },
  methods: {
    set_showeditor() {
      if (this.showeditor == false) {
        this.showeditor =
          this.branch_id != null &&
          this.toText?.length > 0 &&
          this.dateMemo?.length > 0 &&
          this.pageText?.length > 0 &&
          this.subjectText?.length > 0;
      }
      return this.showeditor;
    },

    goBack() {
      let keySearchMemo = this.$route.params.keySearch;
      let currentPageMemo = this.$route.params.currentPage;

      let params = {
        keySearchMemo,
        currentPageMemo,
      };

      this.$router.push({
        path: 'memoList',
        name: 'จัดการ Memo',
        params: params,
      });
    },

    async getBranchList() {
      let params = {
        ...(this.me?.employees.branchs.company_id
          ? { company_id: this.me?.employees.branchs.company_id }
          : {}),
        bch_only: true,
      };

      loading_start();
      let branchList = await this.$store.dispatch('staffs/searchStaff', params);
      loading_close();

      return branchList;
    },

    getPdfTemplate(template) {
      this.pdfme_template = template;
    },

    async getBranchLogo() {
      if (this.branch_id == null) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return;
      }

      if (
        this.branchLogo &&
        typeof this.branchLogo[this.branch_id] != 'undefined'
      ) {
        return this.branchLogo[this.branch_id];
      }

      let params = {
        bch_id: this.branch_id,
      };

      loading_start();
      let branchLogo = await this.$store.dispatch('pdfs/getBranchLogo', params);
      loading_close();

      if (branchLogo.length > 0) {
        this.branchLogo[this.branch_id] = branchLogo[0].image;
      } else {
        this.$store.dispatch('alerts/error', 'ไม่มีโลโก้ของสาขานี้');
      }

      return branchLogo;
    },

    async previewPDF() {
      try {
        if (this.$refs.pdfmeEditor) {
          await this.$refs.pdfmeEditor.generatePDF();
        } else {
          throw 'Error: Could not find the PDF editor reference.';
        }
      } catch (error) {
        throw 'Error generating PDF: ' + error;
      }
    },

    setStaffsAdd(e, index, type = 'approver') {
      if (!e) return;

      if (type == 'acknowledge') {
        this.acknowledgeMemo.splice(index, 1, e);
      } else if (type == 'approver') {
        this.approverMemo.splice(index, 1, e);
      }
      this.$refs.pdfmeEditor.addSignatureToEditor(e, type);
    },

    addAcknowledgeMemo() {
      this.acknowledgeMemo.push({});
    },

    addApproverMemo() {
      this.approverMemo.push({});
    },

    deleteAppMemo(index, type = 'approver') {
      if (type == 'acknowledge') {
        this.acknowledgeMemo.splice(index, 1);
      } else if (type == 'approver') {
        this.approverMemo.splice(index, 1);
      }
    },
    saveMemo() {
      this.$refs.pdfmeEditor.getCurrentTemplate();
      const cleanData = JSON.parse(JSON.stringify(this.pdfme_template));

      this.$store.dispatch('memo/saveMemo', 1);

      // console.log({
      //   content: this.pdfme_template,
      //   cleanData,
      // });
    },
  },
};
</script>
<style lang="scss">
.createMemo {
  .table.memo-table {
    width: 700px;
    margin-bottom: 1rem;
    font-weight: bold;
    td {
      width: 50%;
    }
  }
  .memo-table {
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 25px;
  }
  .headMemo {
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 26px;
  }
  .b-input-memo-table {
    display: inline-block;
    width: auto;
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 25px;
    color: black;
    background-image: none;
    &:focus {
      color: black;
    }
  }
  .b-datepicker-memo.b-form-btn-label-control
    .dropdown
    .b-form-datepicker
    .b-datepicker-memo
    .form-control {
    label {
      display: inline-block;
      font-family: 'Angsana New';
      font-weight: bold;
      font-size: 18px;
      color: black;
    }
  }
  .dropdown-menu .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    font-size: 23px;
    line-height: 0.7;
  }
  .b-calendar-grid small {
    font-size: 19px;
  }
  .b-calendar-grid-caption {
    font-size: 24px;
  }
  .b-calendar-inner bdi {
    font-size: 18px;
  }
  table {
    td {
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      height: 60px;
    }
    p {
      width: 130px;
      margin: 0;
    }
    .form-control,
    .cc {
      height: 35px;
      width: 100%;
    }
  }
}
</style>

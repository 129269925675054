<template>
  <div id="editor"></div>
</template>

<script>
import {
  getInputFromTemplate,
  getDefaultFont,
  DEFAULT_FONT_NAME,
} from '@pdfme/common';
import { Designer } from '@pdfme/ui';
import {
  multiVariableText,
  text,
  barcodes,
  image,
  svg,
  line,
  table,
  rectangle,
  ellipse,
} from '@pdfme/schemas';
import { generate } from '@pdfme/generator';
import $ from 'jquery';
export default {
  name: 'PdfmeEditor',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    branchLogo: {
      type: String,
      default: '',
    },
    memorandam: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      domContainer: null,
      designer: null,
      template: null,
      fontObjList: [
        {
          fallback: true,
          label: 'Angsana New',
          url:
            process.env.VUE_APP_IMAGE_BASE_URL +
            '/storage/font/angsananew/angsana-new-normal.ttf',
        },
        {
          fallback: false,
          label: 'Angsana New Bold',
          url:
            process.env.VUE_APP_IMAGE_BASE_URL +
            '/storage/font/angsananew/angsana-new-bold.ttf',
        },
        {
          fallback: false,
          label: DEFAULT_FONT_NAME,
          data: getDefaultFont()[DEFAULT_FONT_NAME].data,
        },
      ],
      organizedby: null,
      acknowledge: null,
    };
  },
  async mounted() {
    this.domContainer = document.getElementById('editor');
    await this.setDesigner();
    let employee = await JSON.parse(localStorage.getItem('employees'));
    await this.addSignatureToEditor(employee, 'setData');

    const interval = setInterval(() => {
      if (this.addOptionTemplate()) clearInterval(interval);
    }, 200);

    $(document).on('click', '.reset-header', () => this.resetHeaderEditor());
    $(document).on('click', '.add-createby-signature', () => {
      this.organizedby = { ...this.organizedby, status: 0 };
      this.addSignatureCreateBy(this.organizedby.employee);
    });
  },
  watch: {
    async branchLogo(value) {
      this.updateTemplateContent('branch_logo', value);
    },
    memorandam(value) {
      const fieldsMap = {
        fromText: 'FROM',
        pageText: 'PAGE',
        subjectText: 'SUBJECT',
        toText: 'TO',
        dateMemo: 'DATE',
      };

      const template = this.designer?.getTemplate();
      const tableField = template?.schemas[0].find(
        (field) => field.name === 'memorandam_table',
      );

      if (tableField) {
        Object.entries(value).forEach(([key, newValue]) => {
          if (fieldsMap[key]) {
            const replacement = `${fieldsMap[key]}: ${newValue}`;

            if (key === 'toText' || key === 'dateMemo') {
              tableField.head = tableField.head.map((h) =>
                h.startsWith(fieldsMap[key]) ? replacement : h,
              );
            } else {
              try {
                const contentArray = JSON.parse(tableField.content);
                contentArray.forEach((row) => {
                  row.forEach((cell, index) => {
                    if (cell.startsWith(fieldsMap[key])) {
                      row[index] = replacement;
                    }
                  });
                });
                tableField.content = JSON.stringify(contentArray);
              } catch {
                throw 'Invalid content format';
              }
            }
          }
        });
        this.designer.updateTemplate(template);
      }
    },
  },
  methods: {
    addOptionTemplate() {
      const $buttonElement = $(
        'div[role="button"][aria-roledescription="draggable"]',
      ).first();

      if (!$buttonElement.length) return false;

      const options = [
        {
          title: 'Reset Header',
          svg: `<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 27.206 27.206" xml:space="preserve">
                        <g>
	                        <g>
		                        <path d="M17.986,0H4.518v27.168l18.17,0.038V4.686L17.986,0z M21.534,26.05L5.671,26.018V1.154h11.104v5.213h4.759V26.05z
			                        M21.534,5.214h-3.604V1.571l3.604,3.594V5.214z"/>
		                        <path d="M8.595,16.135L6.56,21.66h1.71l0.248-0.789h1.756l0.233,0.789h1.764l-2.012-5.525H8.595z M8.889,19.688l0.534-1.703h0.015
			                        l0.496,1.703H8.889z"/>
		                        <path d="M15.549,11.685c0.111-0.209,0.167-0.453,0.167-0.731c0-0.201-0.027-0.379-0.081-0.533
			                        c-0.055-0.155-0.125-0.289-0.213-0.399c-0.088-0.111-0.189-0.203-0.305-0.275C15,9.675,14.881,9.618,14.756,9.577
			                        c0.207-0.114,0.373-0.252,0.496-0.418c0.123-0.165,0.187-0.388,0.187-0.666c0-0.139-0.024-0.29-0.069-0.453
			                        c-0.047-0.163-0.136-0.313-0.268-0.452c-0.131-0.139-0.312-0.254-0.542-0.345c-0.229-0.09-0.525-0.135-0.886-0.135h-3.027v5.524
			                        h3.134c0.242,0,0.479-0.036,0.712-0.108c0.231-0.072,0.438-0.178,0.619-0.318C15.292,12.068,15.438,11.893,15.549,11.685z
			                        M12.35,8.423h0.897c0.175,0,0.316,0.028,0.422,0.085c0.106,0.058,0.159,0.155,0.159,0.294c0,0.18-0.052,0.302-0.155,0.364
			                        s-0.245,0.093-0.426,0.093H12.35V8.423z M13.959,11.054c-0.036,0.067-0.085,0.12-0.147,0.159s-0.133,0.066-0.213,0.081
			                        c-0.08,0.016-0.164,0.023-0.251,0.023H12.35v-1.029h1.068c0.397,0,0.596,0.167,0.596,0.502
			                        C14.014,10.899,13.995,10.987,13.959,11.054z"/>
		                        <path d="M15.518,15.134c-0.131,0.357-0.198,0.751-0.198,1.185c0,0.408,0.063,0.789,0.187,1.143s0.306,0.658,0.546,0.917
			                        c0.239,0.258,0.532,0.462,0.877,0.612c0.346,0.148,0.743,0.224,1.192,0.224c0.402,0,0.76-0.067,1.076-0.2
			                        c0.313-0.135,0.578-0.312,0.791-0.531c0.213-0.217,0.377-0.465,0.488-0.738c0.109-0.271,0.167-0.549,0.167-0.828h-1.671
			                        c-0.025,0.129-0.061,0.251-0.105,0.364c-0.043,0.114-0.1,0.212-0.17,0.295c-0.07,0.082-0.154,0.146-0.254,0.192
			                        c-0.102,0.047-0.225,0.069-0.367,0.069c-0.203,0-0.369-0.044-0.504-0.132c-0.134-0.087-0.24-0.204-0.32-0.348
			                        c-0.082-0.145-0.139-0.308-0.174-0.487c-0.037-0.181-0.056-0.364-0.056-0.55c0-0.187,0.019-0.368,0.056-0.549
			                        c0.035-0.181,0.092-0.342,0.174-0.486c0.08-0.145,0.187-0.262,0.32-0.348c0.135-0.09,0.303-0.131,0.504-0.131
			                        c0.174,0,0.318,0.032,0.428,0.099c0.111,0.067,0.201,0.145,0.268,0.231c0.066,0.09,0.113,0.178,0.139,0.268
			                        c0.026,0.091,0.045,0.162,0.055,0.213h1.656c-0.072-0.697-0.316-1.234-0.732-1.613c-0.415-0.379-1.004-0.571-1.768-0.577
			                        c-0.428,0-0.813,0.07-1.156,0.209c-0.344,0.139-0.637,0.336-0.883,0.589C15.838,14.475,15.65,14.779,15.518,15.134z"/>
	                        </g>
                        </g>
                      </svg>`,
          className: 'reset-header',
        },
        {
          title: 'Signature CreateBy',
          svg: `<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <path stroke="#535358" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 27C6 19.333 13.5 5 18.5 5 23 5 10 26 15 26c3.5 0 6-10.5 8.5-10.5s-1 9 1 9c2.5 0 4-4 4-4"/>
                </svg>`,
          className: 'add-createby-signature',
        },
      ];
      options.forEach((o) => {
        // console.log({
        //   getTitle: $buttonElement.find(' div[title]').attr('title', o.title),
        // });
        const $optionElement = $buttonElement.clone();
        $optionElement.find('svg').replaceWith(o.svg);
        $optionElement.addClass(o.className);
        $buttonElement.parent().append($optionElement);
      });
      return true;
    },
    updateTemplateContent(fieldName, content, page = 0) {
      if (!this.designer) return;

      const template = this.designer.getTemplate();
      const branchLogoField = template.schemas[page].find(
        (field) => field.name === fieldName,
      );

      if (branchLogoField) {
        branchLogoField.content = content;
        this.designer.updateTemplate(template);
      }
    },
    getBaseTemplate() {
      return {
        schemas: [
          [
            this.getBranchLogoTemplate(),
            {
              name: 'memorandum',
              type: 'text',
              content: 'MEMORANDUM',
              position: { x: 65.96, y: 18.99 },
              width: 78.06,
              height: 6.02,
              rotate: 0,
              alignment: 'center',
              verticalAlignment: 'top',
              fontSize: 16,
              lineHeight: 1,
              characterSpacing: 0,
              fontColor: '#000000',
              backgroundColor: '',
              opacity: 1,
              strikethrough: false,
              underline: false,
              required: false,
              readOnly: false,
              fontName: 'Angsana New Bold',
            },
            {
              name: 'memorandam_table',
              type: 'table',
              position: { x: 20.5, y: 31 },
              width: 169,
              height: 24,
              content: `[["FROM: ${this.memorandam.fromText}","PAGE: ${this.memorandam.pageText}"],["SUBJECT: ${this.memorandam.subjectText}","CC: ${this.memorandam.cc}"]]`,
              showHead: true,
              head: [
                `TO: ${this.memorandam.toText}`,
                `DATE: ${this.memorandam.dateMemo}`,
              ],
              headWidthPercentages: [50, 50],
              tableStyles: { borderWidth: 0.03, borderColor: '#000000' },
              headStyles: {
                fontName: 'Angsana New Bold',
                fontSize: 16,
                characterSpacing: 0,
                alignment: 'left',
                verticalAlignment: 'middle',
                lineHeight: 0,
                fontColor: '#000000',
                borderColor: '#000000',
                backgroundColor: '#ffffff',
                borderWidth: {
                  top: 0.03,
                  right: 0.03,
                  bottom: 0.03,
                  left: 0.03,
                },
                padding: { top: 4, right: 4, bottom: 4, left: 4 },
              },
              bodyStyles: {
                fontName: 'Angsana New Bold',
                fontSize: 16,
                characterSpacing: 0,
                alignment: 'left',
                verticalAlignment: 'middle',
                lineHeight: 0,
                fontColor: '#000000',
                borderColor: '#000000',
                backgroundColor: '#ffffff',
                alternateBackgroundColor: '#ffffff',
                borderWidth: {
                  top: 0.03,
                  right: 0.03,
                  bottom: 0.03,
                  left: 0.03,
                },
                padding: { top: 4, right: 4, bottom: 4, left: 4 },
              },
              columnStyles: {},
              required: false,
              readOnly: false,
            },
          ],
        ],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      };
    },
    getBranchLogoTemplate() {
      return {
        name: 'branch_logo',
        type: 'image',
        content: this.branchLogo,
        position: { x: 66.48, y: 0.79 },
        width: 77.02,
        height: 15.64,
        rotate: 0,
        opacity: 1,
        required: false,
      };
    },
    getTemplateSignature() {
      return [
        {
          name: 'dotted_line',
          type: 'text',
          content: 'DOTTED_LINE',
          position: { x: 1, y: 1 },
          width: 54.96,
          height: 5.75,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'middle',
          fontSize: 16,
          lineHeight: 1,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
        {
          name: 'name',
          type: 'text',
          content: 'NAME',
          position: { x: 1, y: 8 },
          width: 53,
          height: 7,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'middle',
          fontSize: 18,
          lineHeight: 1,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
        {
          name: 'position',
          type: 'text',
          content: 'POSITION',
          position: { x: 1, y: 14 },
          width: 53,
          height: 12.29,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'top',
          fontSize: 18,
          lineHeight: 0.8,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
      ];
    },
    getTemplateSignatureTitle(
      value = {
        name: 'type',
        content: 'TYPE',
        positions: { x: 13, y: 0.5 },
      },
    ) {
      return {
        name: value.name,
        type: 'text',
        content: value.content,
        position: value.positions,
        width: 27,
        height: 7,
        rotate: 0,
        alignment: 'center',
        verticalAlignment: 'middle',
        fontSize: 18,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: '#000000',
        backgroundColor: '',
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: 'Angsana New',
      };
    },
    getInputsSignature(value) {
      return {
        type: value.type,
        dotted_line: '.....................................',
        name: value.name_th,
        position: value.position,
      };
    },
    getTemplateByAndApprover(
      value = { type: 'TYPE', name_th: 'NAME_TH', position: 'POSITION' },
    ) {
      const signatureTemplate = this.getTemplateSignature();
      const inputsTemplate = this.getInputsSignature(value);
      return {
        template: {
          schemas: [[...signatureTemplate]],
          basePdf: { width: 55, height: 28, padding: [0, 0, 0, 0] },
          pdfmeVersion: '5.0.0',
        },
        inputs: [inputsTemplate],
        emp_id: value.emp_id,
      };
    },
    getTemplateAcknowledge(value) {
      const signatureTemplate = this.getTemplateSignature();
      const inputsTemplate = this.getInputsSignature(value);
      let template = {
        schemas: [[...signatureTemplate]],
        basePdf: { width: 55, height: 28, padding: [0, 0, 0, 0] },
        pdfmeVersion: '5.0.0',
      };
      let inputs = [inputsTemplate];
      let titleAcknowledge = null;

      if (this.acknowledge == null) {
        this.acknowledge = -1;
        const signatureTitleTemplate = this.getTemplateSignatureTitle({
          name: 'type_acknowledge',
          content: value.type,
          positions: { x: 91.5, y: 0.5 },
        });
        titleAcknowledge = {
          template: signatureTitleTemplate,
        };
      }

      return { template, inputs, titleAcknowledge, emp_id: value.emp_id };
    },
    getTemplatePosition(template, newLine = false, positions) {
      const existingSchemas = template.schemas[0];
      const maxYValue = Math.max(
        ...existingSchemas.map((field) => field.position.y),
      );

      const maxYFields = existingSchemas.filter(
        (field) => field.position.y === maxYValue,
      );

      const maxXField = maxYFields.reduce((prev, curr) => {
        return curr.position.x + curr.width > prev.position.x + prev.width
          ? curr
          : prev;
      });

      const maxX = maxXField.position.x;
      const maxY = maxXField.position.y;
      const maxWidth = maxXField.width;
      const maxHeight = maxXField.height;

      const effectiveWidth = 210 - 2 * 18;
      const effectiveHeight = 297 - 2 * 18;

      let newY = maxY - 15;
      let newX = maxX + maxWidth + 1;
      if (newX > effectiveWidth || newLine) {
        newX = 18 + 3;
        newY = maxY + maxHeight + 3;
      }

      return { x: newX, y: newY };
    },
    setDataEmployee(employee, type) {
      const typeList = {
        createBy: 'จัดทำโดย',
        approver: 'อนุมัติโดย',
        acknowledge: 'รับทราบโดย',
      };
      return {
        emp_id: employee.emp_id,
        name_th: `คุณ${employee?.first_nameth} ${employee?.last_nameth}`,
        name_en: `คุณ${employee?.first_nameen} ${employee?.last_nameen}`,
        position:
          employee?.posname_en || employee?.salarys?.[0]?.positions?.pos_nameen,
        type: typeList[type],
      };
    },
    async convertPdfToBase64(values, positions) {
      const font = await this.getFontsData();
      const pdf = await generate({
        template: values.template,
        inputs: values.inputs,
        options: {
          font,
          title: `MEMORANDAM:${this.memorandam.subjectText}`,
        },
        plugins: this.getPlugins(),
      });
      const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      const pdfBase64 = await this.getBase64(blob);
      return {
        name: `pdfBase64-${values?.emp_id}`,
        type: 'image',
        content: pdfBase64,
        position: { x: positions.x, y: positions.y },
        width: 55,
        height: 28,
        rotate: 0,
        alignment: 'center',
        verticalAlignment: 'top',
        opacity: 1,
        required: false,
        readOnly: false,
      };
    },
    async addSignatureToEditor(employee, type) {
      if (!employee) return;
      let newLine = false;
      let values = null;
      const base_template = this.designer?.getTemplate();
      const data = this.setDataEmployee(employee, type);
      let positions = this.getTemplatePosition(base_template, newLine);

      if (type != 'acknowledge') {
        await this.addSignatureCreateBy(employee);
        if (type == 'setData') return;
        positions = this.getTemplatePosition(base_template, newLine);
        const signatureTitleTemplate = this.getTemplateSignatureTitle({
          name: 'createBy-' + data.emp_id,
          positions: { x: positions.x + 13, y: positions.y },
          content: data.type,
        });

        base_template.schemas[0].push(signatureTitleTemplate);
        values = this.getTemplateByAndApprover(data);
      } else {
        values = this.getTemplateAcknowledge(data);
        if (values?.titleAcknowledge != null) {
          positions = this.getTemplatePosition(base_template, true);
          values.titleAcknowledge.template.position.y = positions.y;
          base_template.schemas[0].push(values.titleAcknowledge.template);
          await this.designer.updateTemplate(base_template);
          newLine = true;
        }
      }
      const newTemplate = await this.convertPdfToBase64(values, {
        x: positions.x,
        y: positions.y + 15,
      });
      base_template.schemas[0].push(newTemplate);
      this.designer.updateTemplate(base_template);
    },
    async addSignatureCreateBy(employee) {
      if (this.organizedby?.status == -1) return;

      if (this.organizedby == null) {
        this.organizedby = { employee, type: 'createBy' };
      } else if (typeof this.organizedby == 'object') {
        const employee = this.organizedby.employee;
        this.organizedby.status = -1;
        await this.addSignatureToEditor(employee, 'createBy');
      }
    },
    setHeaderTemplate() {
      const template = this.designer?.getTemplate();
      const schemas = template?.schemas;

      if (schemas && schemas.length > 0) {
        const firstPage = schemas[0];
        const logoField = firstPage.find(
          (field) => field.name === 'branch_logo',
        );

        schemas.forEach((page) => {
          if (
            !page.some((field) => field.name === 'branch_logo') &&
            logoField
          ) {
            page.push({ ...logoField });
          }
        });

        this.designer.updateTemplate(template);
      }
    },
    getBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    },
    async getFontsData() {
      const fontDataList = await Promise.all(
        this.fontObjList.map(async (font) => ({
          ...font,
          data:
            font.data ||
            (await fetch(font.url || '').then((res) => res.arrayBuffer())),
        })),
      );

      return fontDataList.reduce(
        (acc, font) => ({ ...acc, [font.label]: font }),
        {},
      );
    },
    async setDesigner() {
      const font = await this.getFontsData();
      if (this.domContainer) {
        this.designer = new Designer({
          domContainer: this.domContainer,
          template: this.getBaseTemplate(),
          options: { font },
          plugins: this.getPlugins(),
        });
      }
    },
    getPlugins() {
      return {
        'Text': text,
        'Multi-Variable Text': multiVariableText,
        'Table': table,
        'Line': line,
        'Rectangle': rectangle,
        'Ellipse': ellipse,
        'Image': image,
        'SVG': svg,
        'QR': barcodes.qrcode,
      };
    },
    async generatePDF(currentRef = this.designer) {
      if (!currentRef) return;
      this.setHeaderTemplate(['branch_logo']);
      const template = currentRef.getTemplate();
      const inputs =
        typeof currentRef.getInputs === 'function'
          ? currentRef.getInputs()
          : getInputFromTemplate(template);
      const font = await this.getFontsData();

      try {
        const pdf = await generate({
          template,
          inputs,
          options: {
            font,
            title: `MEMORANDAM - ${this.memorandam.subjectText}`,
          },
          plugins: this.getPlugins(),
        });
        const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
        window.open(URL.createObjectURL(blob));
      } catch (e) {
        throw e;
      }
    },
    async resetHeaderEditor() {},
    getCurrentTemplate() {
      this.$emit('input', this.designer.getTemplate());
    },
  },
};
</script>

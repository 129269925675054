<template>
  <div>
    <md-card class="SanthiyaSOS">
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
      </md-card-header>
      <md-card-content
        v-for="data in appeal"
        :key="data.id"
        :value="data.appeal_name"
        style="min-height: 600px;"
      >
        <div class="md-layout spacing md-alignment-left memoName">
          <div
            class="md-layout-item md-size-10 md-medium-size-20 md-small-size-25 md-xsmall-size-50"
            style="text-align: left;"
          >
            <label style="margin-top:1.3rem; text-align: left;">
              หัวข้อ:
            </label>
          </div>
          <div
            class="md-layout-item md-size-90 md-medium-size-80 md-small-size-75 md-xsmall-size-100"
            style="text-align: left;"
          >
            <label id="memo_name" style="margin-top:1.3rem; text-align: left;">
              {{ data.appeal_name }}
            </label>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left detail">
          <div
            class="md-layout-item md-size-10 md-medium-size-20 md-small-size-25 md-xsmall-size-50"
            style="text-align: left;"
          >
            <label style="margin-top:1.3rem; text-align: left;">
              รายละเอียด:
            </label>
          </div>
          <div
            class="md-layout-item md-size-90 md-medium-size-80 md-small-size-75 md-xsmall-size-100"
          >
            <p
              id="memo_detail hard_break"
              style="margin-top:1.3rem; text-align: left;"
            >
              {{ data.appeal_detail }}
            </p>
          </div>
        </div>
        <div
          class="md-layout spacing md-alignment-left images"
          v-if="file_image[0]"
        >
          <div
            class="md-layout-item md-size-10 md-medium-size-20 md-small-size-25 md-xsmall-size-50"
            style="text-align: left;"
          >
            <label style="margin-top:1.3rem; text-align: left;">
              Images:
            </label>
          </div>
          <div
            class="md-layout-item md-size-90 md-medium-size-80 md-small-size-75 md-xsmall-size-100 imageSOS"
          >
            <p style="margin-top:1.3rem; text-align: left;">
              <img :src="file_image[0]" @click="show_img_full(file_image[0])" />
            </p>
            <md-button
              class="md-success md-round"
              v-if="file_image[0]"
              :download="file_image[1]"
              :href="file_image[0]"
            >
              <md-icon>vertical_align_bottom</md-icon>
              Download Images
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import $ from 'jquery';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';

export default {
  components: {},
  async created() {
    this.appeal = this.$route.params.data;
    await this.file_download();
  },
  computed: {},
  data() {
    return {
      file_pdf: '',
      file_image: '',
      file_other: '',
      memo_name: '',
      memo_detail: '',
      keySearch: '',
      appeal: {},
    };
  },
  methods: {
    goBack() {
      let keySearch = this.$route.params.keySearch;
      let currentPage = this.$route.params.currentPage;
      let backPage = this.$route.params.backPage;

      if (backPage == 'list') {
        this.$router.push({
          path: 'listAppeal',
          name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
          params: {
            keySearch,
            currentPage,
          },
        });
      } else {
        this.$router.push({
          path: 'listAppeal',
          name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
          params: {
            keySearch,
            currentPage,
          },
        });
      }
    },

    async file_download() {
      try {
        let file_pdf = '';
        let file_image = '';
        let file_other = '';
        let name = '';
        let id = '';
        $.each(this.appeal, function(k, v) {
          name = v.appeal_name;
          id = v.id;
        });
        loading_start();
        let files = await this.getfile(id);
        loading_close();
        files.forEach((file) => {
          file_image = file.image;
          // file_pdf = file.pdf_file_sos;
          // file_other = file.file_other_sos;
        });

        // this.file_pdf = [file_pdf, name, 'pdf'];
        this.file_image = [file_image, name, 'pic'];
        // this.file_other = [file_other, name, ''];
      } catch (error) {
        // console.log(error.message);
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        loading_close();
      }
    },

    async getfile(id) {
      let params = {
        id: id,
      };
      let resp = await this.$store.dispatch('appeal/getFileAppeal', params);
      return resp;
    },

    async show_img_full(file) {
      if (file == '') {
        return;
      }
      var win = window.open();
      if (file.toLowerCase().indexOf('data:application/pdf;') >= 0) {
        win.document.write(
          '<iframe src="' +
            file +
            '" frameborder="0" style="width: 100%; height:100%;" allowfullscreen></iframe>',
        );
      } else {
        win.document.write(
          '<img style="width: 100%; height: auto;" src="' + file + '"></img>',
        );
      }
    },
  },
};
</script>
<style>
p#memo_detail\ hard_break {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.md-card.SanthiyaSOS img {
  width: 60% !important;
  max-width: 100% !important;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.md-card.SanthiyaSOS img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
</style>

import service from '@/store/services/memo-service';

const state = {};

const mutations = {};

const actions = {
  async getMemoList({ commit, dispatch }, params) {
    return await service.getMemoList(params);
  },
  async saveMemo({ commit, dispatch }, params) {
    return await service.saveMemo(params);
  },
};

const getters = {};

const memo = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default memo;

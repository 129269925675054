<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent noprint"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <!-- <div class="md-autocomplete">
            <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
              :md-open-on-focus="false"
            >
              <label v-if="$route.meta.rtlActive">...</label>
              <label v-else>Search...</label>
            </md-autocomplete>
          </div> -->
          <md-list>
            <!-- <md-list-item href="/dashboard">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item> -->

            <li class="md-list-item">
              <a
                class="
                  md-list-item-router md-list-item-container md-button-clean
                  dropdown
                "
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification" v-if="totalnotify > 0">
                        {{ totalnotify }}
                      </span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul
                      class="dropdown-menu dropdown-menu-right"
                      v-if="totalnotify > 0"
                    >
                      <li v-if="totalnotify > 0">
                        <a @click="goTopage('การอนุมัติรายการทั้งหมด')">
                          You have {{ totalnotify }} request for approve
                        </a>
                      </li>
                      <!-- <li v-if="leavetotal > 0">
                        <a @click="goTopage('อนุมัติวันลา')">
                          You have {{ leavetotal }} new Leaves for approve
                        </a>
                      </li>
                      <li v-if="ottotal > 0">
                        <a @click="goTopage('อนุมัติทำงานล่วงเวลา')">
                          You have {{ ottotal }} new OT request for approve
                        </a>
                      </li>
                      <li v-if="keepdaytotal > 0">
                        <a @click="goTopage('อนุมัติทำงานวันหยุด')">
                          You have {{ keepdaytotal }} new keepday request for
                          approve
                        </a>
                      </li>
                      <li v-if="workingtotal > 0">
                        <a @click="goTopage('อนุมัติกะการทำงาน')">
                          You have {{ workingtotal }} new workingtime request
                          for approve
                        </a>
                      </li> -->
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>

            <md-list-item @click="goTopage('User Profile')">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
// import Vuex from 'vuex';
export default {
  created() {},
  props: {
    totalnotify: {
      type: Number,
      default: 0,
    },
    // keepdaytotal: {
    //   type: Number,
    //   default: 0,
    // },
    // workingtotal: {
    //   type: Number,
    //   default: 0,
    // },
    // ottotal: {
    //   type: Number,
    //   default: 0,
    // },
    // leavetotal: {
    //   type: Number,
    //   default: 0,
    // },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    goTopage(namepage) {
      if (this.$router.history.current.name == namepage) {
        return false;
      }
      this.$router.push({ name: namepage });
    },
  },
};
</script>
<style>
@media print {
  .noprint {
    display: none;
  }
}
</style>

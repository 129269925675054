<template>
  <div>
    <input
      class="swal2-input input-privatecode"
      type="password"
      placeholder="Enter Private Code"
      id="swal-input-privatecode"
      @keypress.enter="accept()"
    />
    <i
      class="fa fa-eye-slash"
      id="togglePrivateCode"
      @click="showPrivateCode()"
      style="margin-left: -30px; cursor: pointer;"
    ></i>
    <div class="alerts-oninput" v-show="alertErr">
      {{ txtErr }} <i class="fa fa-times-circle icon-red"></i>
    </div>
  </div>
</template>
<script>
import md5 from 'js-md5';

export default {
  name: 'InputPrivateCode',
  data() {
    return {
      alertErr: false,
      txtErr: '',
    };
  },
  created() {},
  updated: function() {},
  props: {},
  computed: {},
  watch: {},
  methods: {
    async accept() {
      this.securePrivatecode();
    },

    showPrivateCode() {
      const togglePrivateCode = document.querySelector('#togglePrivateCode');
      const privateCode = document.querySelector('#swal-input-privatecode');
      const type =
        privateCode.getAttribute('type') === 'password' ? 'text' : 'password';
      privateCode.setAttribute('type', type);
      togglePrivateCode.classList.toggle('fa-eye');
      togglePrivateCode.classList.toggle('fa-eye-slash');
    },

    async securePrivatecode() {
      const privateCode = document.querySelector('#swal-input-privatecode');
      let employee = JSON.parse(localStorage.getItem('employees'));
      if (
        privateCode.value &&
        privateCode.value != '' &&
        employee.privatecode
      ) {
        if (employee.privatecode === md5(privateCode.value)) {
          localStorage.setItem('privatecode', privateCode.value);
          this.$emit('resultPrivateCode', true);
          return 'suscess';
        } else {
          // await this.$store.dispatch(
          //   'alerts/error',
          //   'Invalid private code !!!',
          // );
          this.$emit('resultPrivateCode', false);
          return 'invalid';
        }
      } else {
        this.alertErr = true;
        this.txtErr = 'กรุณากรอก Private Code!';
        this.$emit('resultPrivateCode', 'empty');
        return 'empty';
      }
    },
  },
};
</script>
<style lang="scss">
.input-privatecode {
  width: 80%;
}
.alerts-oninput {
  background-color: rgb(228, 228, 228);
  font-size: x-large;
}
.icon-red {
  color: red;
}
.md-icon {
  transition: none;
}
</style>

<template>
  <md-card class="edit">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="card-icon">
        <md-icon>add_circle_outline</md-icon>
      </div>
      <h4 class="title"></h4>
      <div class="text-right">
        <md-button @click="goBack" class="md-primary md-dense">
          Back to List
        </md-button>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="md-layout-item md-size-100 md-alignment-top-center">
        <Form-Contract :condata="condata" v-if="showcon_add == true" />
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import $ from 'jquery';
import FormContract from '@/pages/Dashboard/Pages/Admin/Contract/Form_Contract.vue';

export default {
  components: {
    'Form-Contract': FormContract,
  },
  name: 'EditContract',
  mounted: function() {
    this.showfrom_add();
  },
  created() {
    // console.log(this.$route.params.data);
  },
  data() {
    return {
      condata: this.$route.params.data,
      showcon_add: false,
    };
  },
  methods: {
    showfrom_add() {
      this.showcon_add = true;
    },
    goBack() {
      this.$router.push({
        path: 'form-contract',
        name: 'ฟอร์มสัญญาจ้าง',
      });
    },
  },
};
</script>
<style>
@media (max-width: 600px) {
  .line {
    margin-top: -0.5rem;
  }
  .w-42 > input[type='text'][data-v-2b6460fa] {
    height: 2rem !important;
  }
}
</style>

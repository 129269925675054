<template>
  <div>
    <md-dialog :md-active.sync="showEditDialog" md-fullscreen>
      <md-dialog-title>เพิ่มการตั้งค่าวันหยุดประจำสัปดาห์</md-dialog-title>
      <ValidationObserver v-slot="{ handleSubmit }">
        <md-dialog-content>
          <form @submit.prevent="handleSubmit(submit)">
            <div class="md-layout spacing">
              <div
                class="md-layout-item md-size-100 mt-2 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
              >
                <h5>
                  <label>
                    รหัสพนักงาน: {{ staffs.emp_code }} <br />
                    ชื่อ:
                    {{
                      staffs.title_nameth +
                        ' ' +
                        staffs.firstname_th +
                        ' ' +
                        staffs.lastname_th
                    }}
                  </label>
                </h5>
              </div>
              <div
                class="md-layout-item md-size-50 mt-2 md-small-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_weekend_edit.weekend_id"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>เลือก วันหยุดประจำ</label>
                    <md-select
                      v-model="employee_weekend_edit.weekend_id"
                      v-if="weekends"
                    >
                      <md-option
                        v-for="itemweekends in weekends"
                        :key="itemweekends.id"
                        :value="itemweekends.id"
                      >
                        {{ itemweekends.name }}
                      </md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout spacing md-alignment-top-center">
              <div
                class="md-layout-item mt-2 md-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_weekend_edit.from_date"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-datepicker
                    v-model="employee_weekend_edit.from_date"
                    md-immediately
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>วันที่เริ่ม</label>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-datepicker>
                </ValidationProvider>
              </div>
              <div
                class="md-layout-item mt-2 md-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <md-datepicker
                  v-model="employee_weekend_edit.to_date"
                  md-immediately
                  :md-disabled-dates="disabledFinishDates"
                >
                  <label>วันที่สิ้นสุด</label>
                </md-datepicker>
              </div>
            </div>
          </form>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button
            class="md-success"
            @click="staffWeekendCreate(employee_weekend_edit)"
          >
            บันทึก
          </md-button>
          <md-button class="md-danger" @click="showEditDialog = false">
            ปิด
          </md-button>
        </md-dialog-actions>
      </ValidationObserver>
    </md-dialog>
    <!-- Dialog Edit Weeked -->
    <md-dialog :md-active.sync="showEditWeekedDialog" md-fullscreen>
      <md-dialog-title
        ><md-icon>edit</md-icon> แก้ไข การตั้งค่าวันหยุดประจำสัปดาห์
      </md-dialog-title>
      <ValidationObserver v-slot="{ handleSubmit }">
        <md-dialog-content>
          <form @submit.prevent="handleSubmit(submit)">
            <div class="md-layout spacing">
              <div
                class="md-layout-item md-size-100 mt-2 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
              >
                <h5>
                  <label>
                    รหัสพนักงาน: {{ staffs.emp_code }} <br />
                    ชื่อ:
                    {{
                      staffs.title_nameth +
                        ' ' +
                        staffs.firstname_th +
                        ' ' +
                        staffs.lastname_th
                    }}
                  </label>
                </h5>
              </div>
              <div
                class="md-layout-item md-size-50 mt-2 md-small-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_weekend_edit.weekend_id"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>เลือก วันหยุดประจำ</label>
                    <md-select v-model="employee_weekend_edit.weekend_id">
                      <md-option
                        v-for="itemweekends in weekends"
                        :key="itemweekends.id"
                        :value="itemweekends.id"
                      >
                        {{ itemweekends.name }}
                      </md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout spacing md-alignment-top-center">
              <div
                class="md-layout-item mt-2 md-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_weekend_edit.from_date"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-datepicker
                    v-model="employee_weekend_edit.from_date"
                    md-immediately
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>วันที่เริ่ม</label>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-datepicker>
                </ValidationProvider>
              </div>
              <div
                class="md-layout-item mt-2 md-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <md-datepicker
                  v-model="employee_weekend_edit.to_date"
                  md-immediately
                  :md-disabled-dates="disabledFinishDates"
                >
                  <label>วันที่สิ้นสุด</label>
                </md-datepicker>
              </div>
            </div>
          </form>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button
            class="md-success"
            @click="update_weekend(employee_weekend_edit)"
          >
            บันทึก
          </md-button>
          <md-button class="md-danger" @click="showEditWeekedDialog = false">
            ปิด
          </md-button>
        </md-dialog-actions>
      </ValidationObserver>
    </md-dialog>
    <!-- end Dialog Edit Weeked -->

    <div class="md-layout spacing md-alignment-center">
      <div
        class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        รหัสพนักงาน: {{ staffs.emp_code }} <br />
        ชื่อพนักงาน: {{ staffs.title_nameth }}
        {{ staffs.firstname_th + ' ' + staffs.lastname_th }}
      </div>
      <div
        class="md-layout-item md-size-30 md-medium-size-35 md-small-size-50 md-xsmall-size-100"
      >
        <md-datepicker v-model="fromdate" md-immediately>
          <label>วันที่เริ่ม</label>
        </md-datepicker>
      </div>
      <div
        class="md-layout-item md-size-30 md-medium-size-35 md-small-size-50 md-xsmall-size-100"
      >
        <md-datepicker
          v-model="todate"
          :md-disabled-dates="disabledToDates"
          md-immediately
        >
          <label>วันที่สิ้นสุด</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-size-10">
        <md-progress-spinner
          :md-diameter="50"
          :md-stroke="3"
          md-mode="indeterminate"
          :md-value="100"
          v-show="showProgressBar"
        >
        </md-progress-spinner>
      </div>
    </div>

    <div class="md-layout spacing md-alignment-center">
      <div class="md-layout-item md-size-20 md-alignment-center">
        <b-button @click="getEmpWeekendlist()" variant="info" size="lg">
          Search
        </b-button>
      </div>
    </div>
    <div class="md-layout spacing">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-40 md-small-size-100 md-alignment-left"
              >
                <span></span>
              </div>
              <div
                class="md-layout-item md-size-60 md-small-size-100 md-alignment-left text-right"
              >
                <b-button @click="goToAdd()" variant="primary">
                  เพิ่มการตั้งค่า
                </b-button>
              </div>
            </div>
            <div class="md-layout-item md-size-100 mt-4 md-small-size-100">
              <md-table
                v-if="tableData"
                v-model="tableData"
                table-header-color="green"
                class="paginated-table table-striped table-hover"
              >
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="รหัสวันหยุดประจำ">
                    {{ item.weekend_id }}
                  </md-table-cell>
                  <md-table-cell md-label="วันหยุดประจำ">
                    {{ item.name }}
                  </md-table-cell>
                  <md-table-cell md-label="วันที่เริ่ม">
                    {{ item.from_date }}
                  </md-table-cell>
                  <md-table-cell
                    v-if="item.to_date == 'null' || item.to_date == null"
                    md-label="Finish Date"
                  >
                    ยังไม่ได้ระบุ
                  </md-table-cell>
                  <md-table-cell v-else md-label="วันที่สิ้นสุด">
                    {{ item.to_date }}
                  </md-table-cell>
                  <md-table-cell md-label="Action">
                    <md-button
                      class="md-icon-button md-raised md-round md-info"
                      style="margin: 0.2rem"
                      v-if="permissions.includes('process salary')"
                      @click="goToEdit(item)"
                    >
                      <md-icon>edit</md-icon>
                    </md-button>
                    <md-button
                      class="md-icon-button md-raised md-round md-danger"
                      style="margin: 0.2rem"
                      v-if="permissions.includes('process salary')"
                      @click="showDialogDelete(item)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </md-table-cell>

                  <!-- <md-table-cell md-label="Actions">
                  <md-button
                    class="md-icon-button md-raised md-round md-info"
                    style="margin: 0.2rem"
                    @click="goToEdit(item.id)"
                    v-if="permissions.includes('process salary')"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button
                    class="md-icon-button md-raised md-round md-danger"
                    style="margin: 0.2rem"
                    @click="destroy(item.id)"
                    v-if="permissions.includes('process salary')"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-table-cell> -->
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from '@/components';
import Pagination from '@/components/Pagination';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment';
import Vuex from 'vuex';
import Swal from 'sweetalert2';
// import Swal from 'sweetalert2';
import employeeMixin from '@/mixins/employee-mixin';
// import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
extend('required', required);

export default {
  components: {
    // 'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],
  data() {
    return {
      headLabel: 'หยุดประจำสัปดาห์',
      //selected: [],
      weekends: null,
      tableData: null,
      fromdate: null,
      todate: null,
      staffSearchTerm: this.value,
      showProgressBar: false,
      isPermission: true,
      available_branches: [],
      branches: {
        id: null,
        type: 'branches',
      },
      disabledFinishDates: (date) => {
        let passdate = moment(date);
        let fdate = moment(passdate);
        let tdate = moment(this.employee_weekend_edit.from_date);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      employee_weekend_edit: {},
      weekend_edit_id: '',
      branch_id: null,
      //staffList: [],
      staffs: [],
      employee_id: null,
      moment: moment,
      disabledToDates: (date) => {
        /* eslint-disable */
        let passdate = moment(date);
        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);
        /* eslint-enable */
        return datediff <= -1;
      },
      // disabled_employee: true,
      // disabled_add: true,
      showEditDialog: false,
      showEditWeekedDialog: false,
      dataToEdit: [],
    };
  },

  props: {
    permissions: { type: Array, default: null },
    employeeID: null,
  },

  created() {
    this.employee_id = this.employeeID;
    this.getStaffs();
    this.set_Weekend();
    // if (this.$route.params.tabactive) {
    //   this.tabactive = this.$route.params.tabactive;
    // }
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    // employee_weekend_edit: {
    //   handler(v) {},
    //   deep: true,
    // },
  },
  methods: {
    async getStaffs() {
      loading_start();
      let params = {
        emp_id: this.employee_id,
      };
      let staffs = await this.$store.dispatch('staffs/searchStaff', params);
      this.staffs = staffs[0];
      loading_close();
    },

    async getEmpWeekendlist(p) {
      if (typeof p == 'undefined') {
        if (!this.employee_id) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
          return false;
        } else if (!this.fromdate) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
          return false;
        } else if (!this.todate) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
          return false;
        }
      }
      if (typeof p !== 'undefined') {
        var params = {
          emp_id: p.emp_id,
          from_date: moment(p.from_date).format('YYYY-MM-DD') ?? {},
          to_date:
            p.to_date !== null
              ? moment(p.to_date).format('YYYY-MM-DD') !== 'Invalid date'
                ? moment(p.to_date).format('YYYY-MM-DD')
                : {}
              : {},
        };
      } else {
        var params = {
          emp_id: this.employee_id,
          from_date: moment(this.fromdate).format('YYYY-MM-DD') ?? {},
          to_date:
            this.todate !== null
              ? moment(this.todate).format('YYYY-MM-DD') !== 'Invalid date'
                ? moment(this.todate).format('YYYY-MM-DD')
                : {}
              : {},
        };
      }
      // console.log(params);
      // loading_start();
      await this.$store
        .dispatch('staffweekends/empweekendlist', params)
        .then(async (data) => {
          this.tableData = null;
          if (data) {
            data.forEach((d) => {
              d['from_date'] = moment(d['from_date']).format('DD/MM/YYYY');
              d['to_date'] = moment(d['to_date']).format('DD/MM/YYYY');
            });
            this.tableData = data;
          }
          // this.filter_workingtime(fromdate, data);
          // this.disabled_add = false;
        });
      // loading_close();
    },

    // async filter_workingtime(fromdate, workingdata) {
    //   this.tableData = workingdata.filter((working) => {
    //     return (
    //       moment(working.start_date).isAfter(fromdate) ||
    //       moment(working.start_date).isSame(fromdate) ||
    //       working.work_type === 'major'
    //     );
    //   });
    // },

    async staffWeekendCreate(data) {
      if (!this.employee_id) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      } else if (!data.from_date) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!data.to_date) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      } else if (!data.weekend_id) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก วันหยุดประจำ');
        return false;
      }

      let employee_weekend_submit = { ...data };
      employee_weekend_submit.emp_id = this.employee_id;
      employee_weekend_submit.from_date = moment(data.from_date, [
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      ]).format('YYYY-MM-DD');

      employee_weekend_submit.to_date =
        data.to_date !== null
          ? moment(data.to_date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format(
              'YYYY-MM-DD',
            )
          : null;
      employee_weekend_submit.requestdate = moment().format('YYYY-MM-DD');
      await this.$store
        .dispatch('staffweekends/empweekendcreate', employee_weekend_submit)
        .then(
          (data) => {
            this.$store.dispatch('alerts/success', 'บันทึกเรียบร้อยแล้ว');
            this.employee_weekend_edit = {
              emp_weekend_id: null,
              weekend_id: null,
              from_date: null,
              to_date: null,
            };
            this.getEmpWeekendlist(employee_weekend_submit);
          },
          (err) => {
            this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
          },
        );
      this.showEditDialog = false;
    },

    async set_Weekend() {
      loading_start();
      if (this.weekends == null || this.weekends.length <= 0) {
        await this.$store.dispatch('weekends/list');
      }
      this.weekends = await this.$store.getters['weekends/dropdown'];
      loading_close();
    },

    async goToAdd() {
      this.showEditDialog = true;
    },

    async goToEdit(data) {
      this.employee_weekend_edit = {
        emp_weekend_id: data.emp_weekend_id,
        weekend_id: data.weekend_id,
        from_date: data.from_date,
        to_date: data.to_date,
      };
      // this.employee_weekend_edit.emp_weekend_id = data.emp_weekend_id;
      // this.employee_weekend_edit.weekend_id = data.weekend_id;
      // this.employee_weekend_edit.from_date = data.from_date;
      // this.employee_weekend_edit.to_date = data.to_date;
      // console.log(this.employee_weekend_edit);
      this.showEditWeekedDialog = true;
    },
    async update_weekend(data) {
      let data_edit = {};
      if (!this.employee_id) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      } else if (!data.from_date) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!data.to_date) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      } else if (!data.weekend_id) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก วันหยุดประจำ');
        return false;
      }
      data_edit['emp_weekend_id'] = data.emp_weekend_id;
      data_edit['emp_id'] = this.employee_id;
      data_edit['weekend_id'] = data.weekend_id;
      data_edit['from_date'] = moment(data.from_date, [
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      ]).format('YYYY-MM-DD');

      data_edit['to_date'] =
        data.to_date !== null
          ? moment(data.to_date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format(
              'YYYY-MM-DD',
            )
          : null;
      data_edit['requestdate'] = moment().format('YYYY-MM-DD');
      await this.$store
        .dispatch('staffweekends/empweekendEdit', data_edit)
        .then(
          (data) => {
            this.$store.dispatch('alerts/success', 'บันทึกเรียบร้อยแล้ว');
            this.showEditWeekedDialog = false;
            this.getEmpWeekendlist();
          },
          (err) => {
            this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
          },
        );
    },
    async showDialogDelete(data) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ id_delete: data.emp_weekend_id },
            };
            let resp = await this.$store.dispatch(
              'staffweekends/empweekendDelete',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Working time has been deleted.',
                'success',
              );
              this.showEditWeekedDialog = false;
              this.getEmpWeekendlist();
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'Your imaginary working time is safe :)',
              'error',
            );
          }
        });
    },
    // goToEdit(id) {
    //   this.$router.push({
    //     name: 'แก้ไข กะทำงานพนักงาน',
    //     path: '/staff/transaction/editworkingtime',1
    //     params: { id },
    //   });
    // },

    /**
     * set branch value from child component
     */
    // setBranches(v) {
    //   this.branches = v;
    // },

    /**
     * set employee id value from child component
     */
    // setStaffs(v) {
    //   if (v) {
    //     this.staffs = v;
    //   }
    // },
  },
};
</script>

<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>

<template>
  <div class="box-appv">
    <b-container>
      <b-row>
        <b-col
          lg="6"
          md="6"
          sm="12"
          v-for="item_persons in persons"
          :key="item_persons.id"
          :value="item_persons.id"
        >
          <div class="box-appv-control">
            <div class="box-appv-person">
              <strong>
                {{ item_persons.firstname_th }}
                {{ item_persons.lastname_th }} <br />
                {{ item_persons.position_detail }}
              </strong>
              <div>
                <a v-if="item_persons.status == 'approved'">
                  <md-icon class="approve">
                    check_circle
                  </md-icon>
                </a>
                <div v-if="item_persons.status == 'pending'">
                  <a
                    class="pointer"
                    @click="accept_report(item_persons.approver_id)"
                    v-if="item_persons.emp_id == profile.emp_id"
                  >
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                  <a v-else>
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                </div>
                <a v-else-if="item_persons.status == 'disabled'"></a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import md5 from 'js-md5';
// import SignaturePad from 'signature_pad';
export default {
  name: 'BoxOTOverLimitAppved',
  data() {
    return {};
  },
  created() {},
  props: {
    profile: {
      type: Object,
    },
    report_select: {
      type: Object,
      default: null,
    },
    persons: {
      type: Array,
      default: () => {},
    },
    branchs: {
      type: Number,
      default: null,
    },
  },
  methods: {
    async accept_report(approve) {
      if (approve == null) {
        this.$store.dispatch(
          'alerts/error',
          'มีบางอย่างผิดพลาด! : approver_id',
        );
      }

      let params = {
        ...{
          approver_id: approve ? approve : {},
        },
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ report_id: this.report_select.id ? this.report_select.id : {} },
      };
      const confirmation = await Swal.fire({
        title: 'ต้องการอนุมัติรายการนี้หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.dismiss) {
        return;
      } else {
        if (confirmation.value === true) {
          this.$store.dispatch('ots/approveOTOverLimitReport', params).then(
            async (data) => {
              Swal.fire('Approve!', '', 'success');
              this.$emit('getreport', data);
            },
            (err) => {
              Swal.fire('มีบางอย่างผิดพลาด!', '', 'error');
            },
          );
        }
      }
    },
  },
};
</script>
<style lang="scss">
.approve {
  font-size: 35px !important;
  color: rgb(0, 160, 0) !important;
}
.pending {
  font-size: 35px !important;
  color: #feba12 !important;
}
.box-appv-person {
  font-weight: bold;
  font-size: 16.5px;
  font-family: 'Kanit';
  text-align: center;
  padding: 0 1.5em;
  display: inline-block;
  width: 100%;
  margin-top: 0.5em;
}

.box-appv-control {
  margin-top: 2em;
}

.pointer {
  cursor: pointer;
}

.center {
  justify-content: center;
  align-items: center;
}
</style>

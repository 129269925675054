<template>
  <div class="memoList">
    <md-card v-if="false || true">
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>list</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="gotoCreate()" class="md-success md-dense">
            สร้าง Memo
          </md-button>
          <md-button @click="goBack()" class="md-primary md-dense">
            Back to SanthiyaSOS
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div
          class="md-layout-item md-size-5 md-medium-size-10 md-small-size-10 md-xsmall-size-100"
          style="text-align: left"
        >
          <label style="margin-top: 1.3rem; text-align: right">
            ค้นหา:
          </label>
        </div>
        <div
          class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
          style="text-align: left"
        >
          <md-field>
            <label>Search</label>
            <md-input
              class="md-size-50 searchID"
              v-model="keySearch"
              @keyup.enter="setMemoList()"
            ></md-input>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
          style="text-align: left"
        >
          <md-field>
            <label>Limit</label>
            <md-select class="md-size-50" v-model="limit">
              <md-option
                v-for="(item, index) in limitList"
                :key="index + 'limitList'"
                :value="item"
              >
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          style="text-align: left"
        >
          <b-table-simple>
            <b-tr>
              <b-th>No.</b-th>
              <b-th>Memo Name</b-th>
              <b-th>CreateBy / UpdateBy</b-th>
              <b-th>Create Date</b-th>
              <b-th>Action</b-th>
            </b-tr>
            <b-tr v-for="(item, index) in memoList" :key="index + 'memoList'">
              <b-td>{{ index + 1 }}</b-td>
              <b-td>{{ item.memo_name }}</b-td>
              <b-td>
                <span>
                  ผู้สร้าง:
                  {{ item.empcreate_emp_id }}
                  {{
                    item.empcreate_titlename_en +
                      ' ' +
                      item.empcreate_firstname_en +
                      ' ' +
                      item.empcreate_lastname_en
                  }}
                  ({{
                    item.empcreate_titlename_th +
                      ' ' +
                      item.empcreate_firstname_th +
                      ' ' +
                      item.empcreate_lastname_th
                  }})
                </span>
                <br />
                <span v-if="item.empupdate_emp_id">
                  ผู้แก้ไข:
                  {{ item.empupdate_emp_id }}
                  {{
                    item.empupdate_titlename_en +
                      ' ' +
                      item.empupdate_firstname_en +
                      ' ' +
                      item.empupdate_lastname_en
                  }}
                  ({{
                    item.empupdate_titlename_th +
                      ' ' +
                      item.empupdate_firstname_th +
                      ' ' +
                      item.empupdate_lastname_th
                  }})
                </span>
              </b-td>
              <b-td>{{ item.date }}</b-td>
              <b-td>
                <b-button variant="warning" @click="gotoEdit(item.id)">
                  Edit
                </b-button>
                <!-- <b-button @click="gotoDelete(item.memo_id)">Delete</b-button> -->
              </b-td>
            </b-tr>
          </b-table-simple>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import { loading_start, loading_close } from '@/utils/loading.js';
import moment from 'moment';
import Vuex from 'vuex';

export default {
  components: {},
  async created() {
    this.keySearch = this.$route.params.keySearchMemo || '';
    this.currentPage = this.$route.params.currentPageMemo || 1;
    // console.log(this.$route.params);
    await this.setMemoList();
  },

  data() {
    return {
      currentPage: 1,
      limitList: [15, 30, 50, 100],
      limit: 15,
      keySearch: '',
      memoList: [],
    };
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          per.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
  },
  watch: {},
  methods: {
    goBack() {
      let keySearch = this.$route.params.keySearch;
      let currentPage = this.$route.params.currentPage;

      this.$router.push({
        path: 'santhiayaSOS-management',
        name: 'จัดการสัญธิญา SOS',
        params: {
          keySearch,
          currentPage,
        },
      });
    },

    gotoCreate() {
      this.$router.push({
        path: 'createMemo',
        name: 'สร้าง Memo',
        params: { keySearch: this.keySearch, currentPage: this.currentPage },
      });
    },

    gotoEdit(id) {
      let params = {
        id: id,
        keySearch: this.keySearch,
        currentPage: this.currentPage,
      };

      this.$router.push({
        path: 'createMemo',
        name: 'สร้าง Memo',
        params: params,
      });
    },

    async getBranchList() {
      let params = {
        ...(this.me?.employees.branchs.company_id
          ? { company_id: this.me?.employees.branchs.company_id }
          : {}),
        bch_only: true,
      };

      loading_start();
      let branchList = await this.$store.dispatch('staffs/searchStaff', params);
      loading_close();

      return branchList;
    },

    async getMemoList() {
      let params = {
        page: this.currentPage,
        limit: this.limit,
        memo_search: this.keySearch,
      };

      loading_start();
      let memo_list = await this.$store.dispatch('memo/getMemoList', params);
      loading_close();

      return memo_list;
    },

    async setMemoList() {
      let memolist = await this.getMemoList();
      // console.log(memolist);
      this.memoList = memolist.map((memo) => {
        memo.date = moment(memo.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        return memo;
      });
      // console.log(this.memoList);
    },
  },
};
</script>
<style lang="scss"></style>

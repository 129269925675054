<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                manage_accounts
              </md-icon>
            </div>
            <h5 class="title">วันลาคงเหลือของแต่ละปี</h5>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-70 mt-2 md-small-size-100 md-xsmall-size-100 md-alignment-left"
                  >
                    <filter-employee
                      :permissionsList="permissions"
                      @childBranches="setBranchs"
                      @childDepartment="setDepartments"
                      @childStaffs="setStaffs"
                    >
                    </filter-employee>
                  </div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-25 md-xsmall-size-100 md-alignment-left"
                  >
                    <md-field>
                      <label for="">วันคงเหลือปี</label>
                      <md-select v-model="selectyear" :disabled="disabled_year">
                        <md-option
                          :value="itemyear"
                          v-for="itemyear in listyear"
                          :key="itemyear"
                        >
                          {{ itemyear }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div
                    class="md-layout md-layout-item md-size-50 mt-2 md-small-size-75 md-xsmall-size-100"
                  >
                    <div
                      class="md-layout-item md-size-50 mt-2 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-datepicker
                        v-model="selectdate.fromdate"
                        md-immediately
                      >
                        <label>ช่วงเริ่มการลา</label>
                      </md-datepicker>
                    </div>
                    <div
                      class="md-layout-item md-size-50 mt-2 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-datepicker
                        v-model="selectdate.todate"
                        :md-disabled-dates="disabledToDates"
                        md-immediately
                      >
                        <!-- <md-datepicker v-model="selectdate.todate" md-immediately> -->
                        <label>ช่วงสิ้นสุดการลา</label>
                      </md-datepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="Object.keys(tableData).length > 0"
                class="md-layout md-layout-item mt-4 md-size-100 md-small-size-100 md-xsmall-size-100"
              >
                <div
                  class="md-layout-item md-size-50 md-small-size-100 md-xsmall-size-100"
                >
                  <md-field>
                    <label for="">ประเภทข้อมูล</label>
                    <md-select v-model="typedata">
                      <md-option
                        v-if="tableData['remain'].length > 0"
                        value="remain"
                      >
                        มีวันเหลือ
                      </md-option>
                      <md-option
                        v-if="tableData['notremain'].length > 0"
                        value="notremain"
                      >
                        ไม่มีวันเหลือ
                      </md-option>
                      <md-option
                        v-if="tableData['errorremain'].length > 0"
                        value="errorremain"
                      >
                        ใช้วันเกิน
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-size-50 md-small-size-100 md-xsmall-size-100"
                >
                  <b-button
                    v-if="permissions.includes('edit remainleave')"
                    variant="success"
                    class="btn-hover-rl"
                    @click="insert_data_all()"
                  >
                    อัพเดททั้งหมด
                  </b-button>
                  <p class="remark">*จากจำนวนวันคงเหลือและตามประเภทที่เลือก*</p>
                </div>

                <b-table
                  :fields="fields"
                  :items="tableData[typedata]"
                  responsive="sm"
                >
                  <template #cell(empname)="data">
                    {{ data.item.emp.emp_code }} /
                    <br />
                    {{ data.item.emp.name_th }} ({{ data.item.emp.name_en }})
                    <br />
                    {{ data.item.emp.posname_th }} ({{
                      data.item.emp.posname_en
                    }})
                    <!-- <b class="text-info">{{ data.emp }}</b>
                    <b class="text-info">{{ data.emp.name_th }}</b>
                    <b>{{ data.emp.emp_code }}</b> -->
                  </template>
                  <template #cell(leaves)="data">
                    <div
                      class="leave"
                      v-for="(l, index) in data.item.leaves"
                      :key="index + 'leaves'"
                    >
                      {{ moment(l.from_date).format('DD/MM/YYYY') }} -
                      {{ moment(l.to_date).format('DD/MM/YYYY') }} (
                      <template v-if="l.half == 'hm'">ครึ่งเช้า</template>
                      <template v-else-if="l.half == 'hn'">
                        ครึ่งบ่าย
                      </template>
                      <template v-else>เต็มวัน</template>
                      ) ({{ l.useday }})
                      <hr />
                    </div>
                    <!-- <b class="text-info">{{ data.emp }}</b>
                    <b class="text-info">{{ data.emp.name_th }}</b>
                    <b>{{ data.emp.emp_code }}</b> -->
                  </template>
                  <template #cell(totalremain)="data">
                    {{ data.item.emp.totalremain }} ({{ data.item.emp.days }} +
                    {{ data.item.emp.remain }})
                  </template>
                  <template #cell(totaluse)="data">
                    {{ data.item.emp.totaluse }}
                  </template>
                  <template #cell(remainleave)="data">
                    <div class="hover-rl">{{ data.item.emp.remain_leave }}</div>
                  </template>
                  <template #cell(remaininsert)="data">
                    <md-field>
                      <label for="">วันคงเหลือปี {{ selectyear }}</label>
                      <md-input
                        v-model="data.item.emp.remainnew"
                        :value="data.item.emp.remainnew"
                      ></md-input>
                    </md-field>
                    <!-- {{ data.item.emp.remainnew }} -->
                  </template>
                  <template
                    v-if="permissions.includes('edit remainleave')"
                    #cell(actions)="data"
                  >
                    <b-button
                      variant="success"
                      @click="
                        insert_data(
                          data.item.emp.remainnew,
                          data.item.emp.emp_id,
                        )
                      "
                    >
                      อัพเดท
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';
import Vuex from 'vuex';
import XLSX from 'xlsx';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
import moment from 'moment';

export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  data() {
    return {
      moment: moment,
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      fields: [
        { key: 'empname', label: 'รหัส/ชื่อ/ตำแหน่ง' },
        { key: 'leaves', label: 'วันที่ลา' },
        { key: 'totalremain', label: 'วันคงเหลือก่อนใช้' },
        { key: 'totaluse', label: 'จำนวนวันที่ใช้' },
        { key: 'remainleave', label: 'จำนวนวันคงเหลือ' },
        { key: 'remaininsert', label: 'แก้ไข' },
        { key: 'actions', label: 'อัพเดท' },
      ],
      boolean: true,
      status: 'active',
      typedata: 'remain',
      // permissions: [],
      staffs: null,
      listyear: null,
      listmonth: null,
      listmonthly: null,
      selectyear: null,
      selectdate: { fromdate: null, todate: null },
      disabled_year: true,
      selectmonth: null,
      disabled_month: true,
      employee_id: null,
      departments: null,
      branchs: null,
      employee: null,
      showEdit: false,
      showAdd: false,
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');
        let fdate = moment(passdate);
        let tdate = moment(this.selectdate.fromdate, 'DD/MM/YYYY');
        return fdate.diff(tdate, 'days') < 0;
      },
    };
  },
  async created() {
    // await this.setPermission(this.me);
    await this.getListYear();
    // await this.getListMonth();
  },
  watch: {
    selectyear: function(val) {
      if (
        val != null &&
        this.selectdate.fromdate != null &&
        this.selectdate.todate != null
      ) {
        this.getListEmp();
        this.selectmonth = null;
        this.disabled_month = false;
      }
    },
    selectdate: {
      handler(val) {
        if (
          this.selectyear != null &&
          val.fromdate != null &&
          val.todate != null
        ) {
          this.getListEmp();
        }
      },
      deep: true,
    },
    // selectmonth: function(val) {
    //   if (val != null) {
    //     this.getListEmp();
    //   }
    // },
    branchs: function(val) {
      if (val != null) {
        if (
          this.selectyear != null &&
          this.selectdate.fromdate != null &&
          this.selectdate.todate != null
        ) {
          this.getListEmp();
        }
      }
    },
    departments: function(val) {
      if (val != null) {
        if (
          this.selectyear != null &&
          this.selectdate.fromdate != null &&
          this.selectdate.todate != null
        ) {
          this.getListEmp();
        }
      }
    },
    employee_id: function(val) {
      if (val != null) {
        if (
          this.selectyear != null &&
          this.selectdate.fromdate != null &&
          this.selectdate.todate != null
        ) {
          this.getListEmp();
        }
      }
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var pms = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },
  methods: {
    async getListYear() {
      loading_start();
      let params = { profile: true };
      this.employee = await this.$store.dispatch('staffs/searchStaff', params);
      let listyear = [];
      const currentYear = Number(moment().format('Y'));
      for (let index = 2021; index <= currentYear + 1; index++) {
        listyear.push(index);
      }
      this.listyear = listyear;
      loading_close();
    },

    async getListMonth() {
      loading_start();
      // let params = {
      //   com_id: this.employee[0].com_id,
      //   ...(this.selectyear ? { years: this.selectyear } : {}),
      // };
      // this.listmonth = await this.$store.dispatch(
      //   'limittaxs/listMonth',
      //   params,
      // );
      let listmonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      // for (let index = 1; index <= 12; index++) {
      //   listmonth.push(index);
      // }
      this.listmonth = listmonth;
      loading_close();
    },

    async getListEmp() {
      loading_start();
      let params = {
        ...(this.employee[0].com_id ? { com_id: this.employee[0].com_id } : {}),
        ...(this.branchs ? { bch_id: this.branchs } : {}),
        ...(this.departments ? { dep_id: this.departments } : {}),
        ...(this.employee_id ? { emp_id: this.employee_id.emp_id } : {}),
        ...(this.selectyear ? { year: this.selectyear } : {}),
        ...(this.selecttype ? { type: this.selecttype } : {}),
        ...(this.selectdate.fromdate
          ? { fromdate: moment(this.selectdate.fromdate).format('YYYY-MM-DD') }
          : {}),
        ...(this.selectdate.todate
          ? { todate: moment(this.selectdate.todate).format('YYYY-MM-DD') }
          : {}),
        // ...(this.selectmonth ? { months: this.selectmonth } : {}),
      };
      try {
        this.tableData = [];
        this.tableData = await this.$store.dispatch(
          'leaves/reportremainleave',
          params,
        );
        // console.log(this.tableData);
      } catch (error) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
      }
      loading_close();
    },

    onExport() {
      const data = this.tableData.map((d) => ({
        date: d.date,
        in: d.timein,
        out: d.timeout,
        break: d.timebreak,
        finishbreak: d.timefinishbreak,
        status: d.workstatus,
        minutetolate: d.timetolatein,
        minuteoff: d.timetolateout,
        workingtime:
          moment(d.condition.start_date + ' ' + d.condition.start_time).format(
            'HH:mm',
          ) +
          ' - ' +
          moment(d.condition.start_date + ' ' + d.condition.finish_time).format(
            'HH:mm',
          ),
      }));
      const dataWS = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      const file_name = this.employeeID + '_timeinout_export' + '.xlsx';
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, file_name);
    },

    async insert_data(remain, emp_id) {
      if (emp_id == null || remain == null) {
        this.$store.dispatch('alerts/error', 'กรอกข้อมูลให้ครบ');
        return false;
      }
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        let params = {
          ...(emp_id ? { emp_id: emp_id } : {}),
          ...(this.selectyear ? { year: this.selectyear } : {}),
          ...(remain ? { remain: remain } : {}),
        };
        try {
          loading_start();
          await this.$store.dispatch('leaves/insertremainleave', params);
          this.getListEmp();
          this.$store.dispatch('alerts/success', 'ตั้งค่าเรียบร้อยแล้ว.');
        } catch (error) {
          this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        }
        loading_close();
      }
    },
    async insert_data_all() {
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        let params = {
          ...(this.employee[0].com_id
            ? { com_id: this.employee[0].com_id }
            : {}),
          ...(this.branchs ? { bch_id: this.branchs } : {}),
          ...(this.departments ? { dep_id: this.departments } : {}),
          ...(this.employee_id ? { emp_id: this.employee_id.emp_id } : {}),
          ...(this.selectyear ? { year: this.selectyear } : {}),
          ...(this.selecttype ? { type: this.selecttype } : {}),
          ...(this.selectdate.fromdate
            ? {
                fromdate: moment(this.selectdate.fromdate).format('YYYY-MM-DD'),
              }
            : {}),
          ...(this.selectdate.todate
            ? { todate: moment(this.selectdate.todate).format('YYYY-MM-DD') }
            : {}),
          type: 'insert',
          typeinsert: this.typedata,
        };
        try {
          loading_start();
          this.tableData = [];
          this.tableData = await this.$store.dispatch(
            'leaves/reportremainleave',
            params,
          );
          this.getListEmp();
          this.$store.dispatch('alerts/success', 'ตั้งค่าเรียบร้อยแล้ว.');
        } catch (error) {
          this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        }
        loading_close();
      }
    },

    // set branch value from child component
    async setBranchs(v) {
      if (v && v != null) {
        this.branchs = v;
        this.disabled_year = false;
      }
    },
    // set departments value from child component
    async setDepartments(v) {
      if (v && v != []) {
        this.departments = v;
        this.disabled_year = false;
      }
    },
    // set staffs value from child component
    async setStaffs(v) {
      if (v && v != []) {
        this.employee_id = v;
        this.disabled_year = false;
      } else {
        this.employee_id = null;
      }
    },

    // setPermission(val) {
    //   val.permissions.map((permis) => {
    //     this.permissions.push(permis.name);
    //   });
    //   val.roles[0].permissions.map((permission) => {
    //     this.permissions.push(permission.name);
    //   });
    // },
  },
};
</script>
<style>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
.backwhite {
  background-color: white;
}
.leave:last-child > hr {
  display: none;
}
.leave:nth-child(even) {
  background-color: #ededed;
}
.leave:hover {
  background-color: #e3e3e3;
}
.remark {
  font-size: small;
  color: red;
}
</style>

<template>
  <div>
    <BoxApprovePri
      :show="private_show"
      @update-show="updateShow"
      @callback="getOTOverLimitReport(), (disabledBtnPrivateCode = true)"
    ></BoxApprovePri>
    <b-container fluid>
      <b-row>
        <!-- <b-col>
          <b-button class="" variant="success" @click="exportPDF()">
            PDF
          </b-button>
          <b-button class="" variant="success" @click="exportExcel()">
            EXPORT
          </b-button>
        </b-col> -->
        <b-col cols="12" sm="12" md="10" lg="10">
          <h3 align="center">OT เกิน 3 ชั่วโมง</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="6" md="3" lg="3" class="mt-2">
          <b-form-select v-model="branchs">
            <b-form-select-option :value="null" disabled>
              เลือก สำนักงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_branchs"
              :key="item.bch_id"
              :value="item.bch_id"
            >
              {{ item.brname_th }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="12" sm="6" md="3" lg="3" class="mt-2">
          <b-form-select v-model="years">
            <b-form-select-option :value="null" disabled>
              เลือก ปี
            </b-form-select-option>
            <b-form-select-option
              v-for="item in listyear"
              :key="item"
              :value="item"
            >
              {{ item }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" class="mt-2">
          <b-form-select v-model="report_select" :disabled="disabled_rp">
            <b-form-select-option :value="null">
              เลือก รายงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_report"
              :key="item.report_id"
              :value="item"
            >
              รายการที่ {{ item.ref_no }} เดือน {{ item.month }} ปี
              {{ item.year }}
              <!-- <span v-if="item.stafftype == 'day'">(รายวัน)</span>
              <span v-else-if="item.stafftype == 'month'">(รายเดือน)</span>
              <span v-else-if="item.stafftype == 'all'">(ทั้งหมด)</span> -->
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="12" sm="12" md="3" lg="3" class="mt-2">
          <b-input-group>
            <b-form-select v-model="departments" :disabled="disabled_dep">
              <b-form-select-option :value="null">
                เลือก แผนก
              </b-form-select-option>
              <b-form-select-option
                v-for="item in available_departments"
                :key="item.dep_id"
                :value="item.dep_id"
              >
                {{ item.depname_th }}
              </b-form-select-option>
            </b-form-select>
            <b-input-group-append>
              <b-button variant="danger" @click="clearDepartment()">
                X
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" sm="12" md="8" lg="8" xl="6">
          <b-button
            class="mt-2"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            v-b-tooltip.hover
            title="ดึงข้อมูล OT ที่ยังไม่ได้ถูกสร้างเป็นรายงาน"
            variant="primary"
            @click="getOTOverLimit()"
          >
            ดึงข้อมูล OT
          </b-button>
          <b-button
            class="mt-2"
            style="border-radius:0;"
            v-b-tooltip.hover
            title="สร้างรายงาน OT"
            variant="success"
            :disabled="report_select != null"
            @click="createOTOverLimitReport()"
          >
            สร้างรายงาน OT
          </b-button>
          <b-button
            class="mt-2"
            style="border-top-left-radius:0; border-bottom-left-radius:0;"
            v-b-tooltip.hover
            title="กรอกเพื่อดูค่าแรงของรายงาน"
            variant="warning"
            v-show="showBtnPrivateCode"
            :disabled="disabledBtnPrivateCode"
            @click="private_show = !private_show"
          >
            <i class="md-icon">lock</i>
            Private Code
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-2" fluid>
      <div class="box-header-otlimit" v-if="report_select">
        <h5>
          รายการที่ {{ report_select.ref_no }} ของเดือน
          {{ report_select.month }} ปี {{ report_select.year }}
        </h5>
      </div>
      <b-table-simple
        sticky-header="750px"
        v-if="otlist"
        class="tablebordercolor"
      >
        <b-thead head-variant="dark" class="theadbordercolor">
          <b-tr>
            <b-th>No.</b-th>
            <b-th>รหัสพนักงาน</b-th>
            <b-th sticky-column>ชื่อพนักงาน</b-th>
            <b-th>แผนก</b-th>
            <b-th>ตำแหน่ง</b-th>
            <b-th>วันที่</b-th>
            <b-th>งานที่ปฏิบัติ</b-th>
            <b-th>ชั่วโมง/นาที</b-th>
            <b-th>เวลาเข้า OT</b-th>
            <b-th>เวลาออก OT</b-th>
            <b-th>ประเภท</b-th>
            <b-th>ค่าแรง</b-th>
            <b-th>ชื่อผู้อนุมัติหรือหัวหน้าแผนก</b-th>
          </b-tr>
        </b-thead>
        <b-tbody class="background-white">
          <b-tr
            v-for="(ot, index) in otlist"
            :key="ot.id + 'data' + index"
            class="otlimit"
            :class="
              ot.approve_status == 'approve' && ot.ot_active == 1
                ? 'otlimitapprove'
                : ot.approve_status == 'disapprove' && ot.ot_active == 1
                ? 'otlimitdisapprove'
                : ''
            "
          >
            <b-td>{{ index + 1 }}</b-td>
            <!-- <b-td>
              <div class="photo">
                <b-img
                  class="img pointer"
                  rounded="circle"
                  :src="`${items.images}`"
                  @click="openImages(items.images)"
                  @error="replaceByDefault"
                />
              </div>
            </b-td> -->
            <b-td>{{ ot.emp_code }}</b-td>
            <b-th sticky-column>
              <span>
                {{ ot.emp_fullname_th }}
              </span>
            </b-th>
            <b-td>{{ ot.dep_nameth }}</b-td>
            <b-td>{{ ot.pos_nameth }}</b-td>
            <b-td>{{ ot.from_date_format }}</b-td>
            <b-td>{{ ot.notes }}</b-td>
            <b-td>{{ ot.h_m }}</b-td>
            <b-td>{{ ot.ot_time_in }}</b-td>
            <b-td>{{ ot.ot_time_out }}</b-td>
            <b-td>{{ ot.otname }}</b-td>
            <b-td>{{ ot.ot_cost }}</b-td>
            <b-td>{{ ot.fullname_th_approver }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot foot-variant="dark">
          <b-tr>
            <b-th></b-th>
            <b-th></b-th>
            <b-th sticky-column></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th>{{ sum_all.ot_cost }}</b-th>
            <b-th></b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-container>
    <BoxOTOverLimitAppved
      v-if="report_select && persons"
      :report_select="report_select"
      :persons="persons"
      :branchs="branchs"
      :profile="profile"
      @getreport="setApprover"
    />
  </div>
</template>
<script>
import $ from 'jquery';
import BoxOTOverLimitAppved from '@/pages/Dashboard/Components/BoxOTOverLimitAppved.vue';
import BoxApprovePri from '@/pages/Dashboard/Components/BoxApprovePri.vue';
import moment from 'moment';
import Vuex from 'vuex';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';

// import SignaturePad from 'signature_pad';
export default {
  name: 'ApprovedOTOverLimit',
  components: {
    BoxOTOverLimitAppved,
    BoxApprovePri,
  },
  mixins: [],

  data() {
    return {
      mainProps: {
        width: 75,
        height: 75,
        class: 'm1',
      },
      listyear: [],
      years: null,
      profile: null,
      branchs: null,
      sum_all: { ot_cost: 0 },
      available_branchs: null,
      departments: null,
      available_departments: null,
      disabled_dep: true,
      disabled_rp: true,
      report_list: null,
      report_select: null,
      persons: null,
      otlist: null,
      available_report: null,
      host: process.env.VUE_APP_API_BASE_URL,
      accesstoken: localStorage.getItem(
        'vue-authenticate.vueauth_access_token',
      ),
      comIds: [],
      avatar: process.env.VUE_APP_APP_BASE_URL + '/img/default-avatar.png',
      showImgDialog: false,
      showImg: null,
      private_show: false,
      showBtnPrivateCode: false,
      disabledBtnPrivateCode: false,
    };
  },

  async created() {
    moment.locale('th');
    loading_start();
    let params = { profile: true };
    this.getLocalStorageEmp();
    let profile = await this.$store.dispatch('staffs/searchStaff', params);
    this.profile = { ...profile[0] };
    let appOTLimit = await this.approverOTOverLimit();
    let arrAppID = [];
    appOTLimit.forEach((app) => {
      if (app.emp_id == this.profile.emp_id) {
        arrAppID.push(app.branch_id);
      }
    });
    arrAppID = [...new Set(arrAppID)];

    let branchs_list = await this.getBranchs();

    if (this.permissions.includes('process salary')) {
      this.available_branchs = branchs_list;
    } else {
      var arr = [];
      branchs_list.map((bch) => {
        if (
          this.permissions.includes(bch.brname_en) ||
          arrAppID.includes(bch.bch_id)
        ) {
          arr.push(bch);
        }
      });
      this.available_branchs = arr;
    }

    loading_close();
  },

  mounted() {},

  props: {},

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },

  watch: {
    branchs: function() {
      this.years = null;
      this.otlist = null;
      this.available_report = null;
      this.report_select = null;
      this.listyear = [2024];
    },
    years: async function() {
      if (this.branchs == null) {
        this.listyear = null;
        this.years = null;
        return;
      }
      if (this.years == null) {
        this.report_select = null;
        this.available_report = null;
        this.otlist = null;
        return;
      }
      await this.getOTOverLimitReportList();
    },
    departments: function() {
      if (this.otlist != null) {
        // await this.filterDepartment();
      }
    },
    report_select: async function() {
      if (this.report_select == null) {
        this.otlist = null;
        return;
      }
      await this.getOTOverLimitReport();
    },
  },

  methods: {
    updateShow(v) {
      this.private_show = v;
    },

    getLocalStorageEmp() {
      let emp_privatecode = JSON.parse(localStorage.getItem('employees'))[
        'privatecode'
      ];

      let privatecode = localStorage.getItem('privatecode');

      if (emp_privatecode) {
        this.showBtnPrivateCode = true;
      }

      if (privatecode) {
        this.disabledBtnPrivateCode = true;
      }
    },

    replaceByDefault(e) {
      e.target.src = this.avatar;
    },

    openImages(img) {
      this.showImg = img;
      this.showImgDialog = true;
    },

    async getBranchs() {
      let params = {
        com_id: this.profile.com_id,
        bch_only: '1',
      };

      let available_branchs = await this.$store.dispatch(
        'staffs/searchStaff',
        params,
      );

      return available_branchs;
    },

    async getDepartments() {
      // this.departments = null;
      // let params = {
      //   com_id: this.profile.com_id,
      //   ...{ bch_id: this.branchs ? this.branchs : {} },
      //   dep_only: '1',
      // };
      // await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
      //   this.available_departments = data;
      //   if (this.available_departments.length > 0) {
      //     this.disabled_dep = false;
      //   }
      // });
    },

    async getOTOverLimit() {
      if (this.branchs == null) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสำนักงาน');
        return;
      }
      this.years = null;

      let params = {
        bch_id: this.branchs,
        // ...{ year: moment().year() },
        ...{ ot_id: [1, 2, 3] },
        ...{ ot_active: 0 },
        ...{ created_ot_over: 0 },
      };
      this.otlist = null;

      loading_start();
      let list = await this.$store.dispatch('ots/getOTOverLimit', params);
      if (list.length < 1) {
        this.$store.dispatch('alerts/error', 'ไม่มีข้อมูล');
        loading_close();
        return;
      }

      list = this.mapFormatOTlist(list);

      this.otlist = list;
      loading_close();

      this.$store.dispatch('alerts/success', 'ดึงข้อมูลเรียบร้อยแล้ว');
    },

    async getOTOverLimitReportList() {
      loading_start();
      let params = {
        ...{ years: this.years ? this.years : {} },
        ...{ bch_id: this.branchs ? this.branchs : {} },
      };
      let data = await this.$store.dispatch(
        'ots/getOTOverLimitReportList',
        params,
      );

      let years = this.years + 543;
      data.forEach((d) => {
        d.name_fromdate_th = moment(d.from_date)
          .format('ll')
          .replace(this.years, years);
        d.name_fromdate_full_th = moment(d.from_date)
          .format('LL')
          .replace(this.years, years);

        d.name_todate_th = moment(d.to_date)
          .format('ll')
          .replace(this.years, years);
        d.name_todate_full_th = moment(d.to_date)
          .format('LL')
          .replace(this.years, years);

        d.name_pay_date_th = moment(d.pay_date)
          .format('ll')
          .replace(this.years, years);
        d.name_pay_date_full_th = moment(d.pay_date)
          .format('LL')
          .replace(this.years, years);
      });

      if (data.length > 0) {
        this.available_report = data;
        this.disabled_rp = false;
      } else {
        this.available_report = null;
        this.report_select = null;
        this.disabled_rp = false;
      }
      loading_close();
    },

    async getOTOverLimitReport() {
      this.otlist = null;

      if (this.report_select == null) {
        return;
      }
      loading_start();
      let privatecode = localStorage.getItem('privatecode')
        ? localStorage.getItem('privatecode')
        : '';
      let params = {
        privatecode: privatecode,
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ year: this.years ? this.years : {} },
        ...{
          emp_ot_id: this.report_select.emp_ot_id_array
            ? this.report_select.emp_ot_id_array
            : [],
        },
      };

      let list = await this.$store.dispatch('ots/getOTOverLimitReport', params);

      list['otoverlimitlist'] = this.mapFormatOTlist(list['otoverlimitlist']);

      this.otlist = list['otoverlimitlist'];
      this.setPriority(this.report_select.approver, list['approver']);
      loading_close();
    },

    mapFormatOTlist(arr = []) {
      this.sum_all.ot_cost = 0;

      arr.map((li) => {
        let from_date_moment = moment(li.from_date);
        let to_date_moment = moment(li.to_date);
        li.from_date_format = moment(from_date_moment).format('DD/MM/YYYY');
        li.to_date_format = moment(to_date_moment).format('DD/MM/YYYY');
        li.ot_time_in = moment(from_date_moment).format('HH:mm');
        li.ot_time_out = moment(to_date_moment).format('HH:mm');
        li.minutes = moment(to_date_moment).diff(
          moment(from_date_moment),
          'minutes',
        );
        li.hours = moment(to_date_moment).diff(
          moment(from_date_moment),
          'hours',
        );
        li.h_m = li.hours + ' ชั่วโมง ' + (li.minutes % 60) + ' นาที';
        this.sum_all.ot_cost += parseFloat(li.ot_cost);
      });
      return arr;
    },

    async createOTOverLimitReport() {
      try {
        if (this.otlist <= 0) {
          this.$store.dispatch(
            'alerts/error',
            'ไม่มีรายการ OT ที่จะนำไปสร้างรายงาน!',
          );
          return;
        }
        let create = {
          id: [],
          year: moment().year(),
          month: moment().month() + 1,
          branch_id: this.branchs,
          emp_create: this.profile['id'],
        };
        this.otlist.forEach((t) => {
          create['id'].push(t.id);
        });

        await this.$store.dispatch('ots/createOTOverLimitReport', create);
        this.otlist = null;
        this.$store.dispatch('alerts/success', 'สร้างรายงานเรียบร้อยแล้ว');
      } catch (error) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
      }
    },

    async approverOTOverLimit(bch_id) {
      let params = { bch_id: { ...bch_id } };
      let approver = await this.$store.dispatch(
        'ots/approverOTOverLimit',
        params,
      );

      return approver;
    },

    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },

    setApprover(approver) {
      // console.log(approver);
      this.setPriority(approver['reportapprover'], approver['approver']);
    },

    async setPriority(temp_approver, approverall) {
      var persons = [...approverall];
      let array_approver = [];
      var priority = [];
      var priority_count = [];
      var approve_count = 0;

      // console.log({ temp_approver: temp_approver });
      // console.log({ persons: persons });

      temp_approver.forEach((approver) => {
        array_approver.push(approver);
        approve_count++;
      });

      let i = 0;
      persons.forEach((p) => {
        priority.push(p.priority);
        i++;
        priority_count[p.priority] = { pri: p.priority, c: i };
      });

      let now_pri = priority_count.filter((pc) => {
        return pc.c <= approve_count;
      });

      let max_pri = priority_count[1];
      if (now_pri.length > 0) {
        max_pri = now_pri.reduce((max, el) => (max.c > el.c ? max : el));
      }

      persons.forEach(async (p) => {
        p.status = 'disabled';
        let have = await array_approver.find((arr_ap) => {
          return arr_ap == p.approver_id;
        });
        if (have) {
          p.status = 'approved';
        } else if (approve_count < 1) {
          if (p.priority == 1) {
            p.status = 'pending';
          }
        } else {
          if (p.priority == max_pri.pri) {
            p.status = 'pending';
          } else if (
            approve_count >= max_pri.c &&
            p.priority == max_pri.pri + 1
          ) {
            p.status = 'pending';
          }
        }
      });
      persons.sort((a, b) => {
        if (a.number < b.number) return 1;
        if (a.number > b.number) return -1;
        return 0;
      });
      this.persons = await Promise.all(persons);
    },

    clearDepartment() {
      if (this.departments == null) {
        return false;
      }
      this.departments = null;
    },

    to_Base64(img) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(img);
      });
    },

    number_format(num = 0) {
      let new_num = parseFloat(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return new_num;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');

.otlimit.otlimitapprove {
  background-color: rgba(0, 128, 0, 0.2) !important;
}
.otlimit.otlimitdisapprove {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.box-header-otlimit {
  text-align: center;
  font-weight: bold;
  font-family: 'Kanit';
  padding: 1em 0;
}
.tablebordercolor .theadbordercolor > tr > th {
  background-color: #343a40;
  border-color: #ddd;
}
.background-white {
  background-color: white;
}
.font-imp {
  font-size: 18px !important;
  color: rgb(255, 255, 255) !important;
}
.font-size {
  font-size: 12px !important;
}
.photo {
  width: 54px;
  height: 54px;
  overflow: hidden;
  border-radius: 50%;
}
.imgclass {
  text-align: center;
  width: 250px !important;
  height: 250px !important;
  overflow: hidden !important;
  display: inline-block;
}
/*
canvas.signature-pad {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
}
.tb-salary {
  .th-w-100 {
    min-width: 100px;
  }
  .th-w-150 {
    min-width: 150px;
  }
  .th-w-200 {
    min-width: 200px;
  }
  .th-w-250 {
    min-width: 250px;
  }
  .th-w-300 {
    min-width: 300px;
  }

  thead th {
    min-width: 100px;
    text-align: center;
    font-size: 14px;
  }
  tbody th,
  tbody td {
    text-align: center;
  }
}
*/
</style>

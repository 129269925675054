import Vue from 'vue';
import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue';
import AuthLayout from '@/pages/Dashboard/Pages/AuthLayout.vue';

// Dashboard pages
import Dashboard from '@/pages/Dashboard/Dashboard.vue';
// Pages
import Login from '@/pages/Dashboard/Pages/Login.vue';

//Working pages
//import TimeInOut from '@/pages/Dashboard/Pages/Staff/TimeInOut/TimeInOut.vue';

// Profile
import UserProfile from '@/pages/Dashboard/Pages/Profile/UserProfile.vue';
import ResetPassword from '@/pages/Dashboard/Pages/Profile/ResetPassword.vue';

//Transaction
import Transaction from '@/pages/Dashboard/Pages/Staff/Transactions/TransactionPage.vue';

// Staff Management
import ListUserPage from '@/pages/Dashboard/Pages/Admin/UserManagement/ListUserPageall.vue';
//import EditUserPage from '@/pages/Dashboard/Pages/Admin/UserManagement/EditUserPage.vue';
import AddStaffPage from '@/pages/Dashboard/Pages/Admin/UserManagement/AddStaffPage.vue';
import EditStaffPage from '@/pages/Dashboard/Pages/Admin/UserManagement/EditStaffPage.vue';

// Workingtime Management
import ListWorkingTimePage from '@/pages/Dashboard/Pages/Admin/Workingtime/ListWorkingTimePage.vue';
import AddWorkingTimePage from '@/pages/Dashboard/Pages/Admin/Workingtime/AddWorkingtimePage.vue';
import EditWorkingTimePage from '@/pages/Dashboard/Pages/Admin/Workingtime/EditWorkingtimePage.vue';
import ChangeShiftWorkForManager from '@/pages/Dashboard/Pages/Admin/ChangeShiftWorkForManager.vue';

// Staff Workingtime Management
import AddWorkignTimeStaffPage from '@/pages/Dashboard/Pages/Staff/Transactions/Working/AddWorkingTimeStaff.vue';
import EditWorkignTimeStaffPage from '@/pages/Dashboard/Pages/Staff/Transactions/Working/EditWorkingTimeStaff.vue';
import CalendatWorkignPage from '@/pages/Dashboard/Pages/Staff/Transactions/Working/CalendarWorking.vue';

// Weekend Management
import ListWeekendPage from '@/pages/Dashboard/Pages/Admin/Weekend/ListWeekendPage.vue';
import AddWeekendPage from '@/pages/Dashboard/Pages/Admin/Weekend/AddWeekendPage.vue';
import EditWeekendPage from '@/pages/Dashboard/Pages/Admin/Weekend/EditWeekendPage.vue';

// Department Management
import ListDepartmentPage from '@/pages/Dashboard/Pages/Admin/Department/ListDepartmentPage.vue';
import AddDepartmentPage from '@/pages/Dashboard/Pages/Admin/Department/AddDepartmentPage.vue';
import EditDepartmentPage from '@/pages/Dashboard/Pages/Admin/Department/EditDepartmentPage.vue';

// Position Management
import ListPositionPage from '@/pages/Dashboard/Pages/Admin/Position/ListPositionPage.vue';
import AddPositionPage from '@/pages/Dashboard/Pages/Admin/Position/AddPositionPage.vue';
import EditPositionPage from '@/pages/Dashboard/Pages/Admin/Position/EditPositionPage.vue';

// Role Management
import ListRolePage from '@/pages/Dashboard/Pages/Admin/RoleManagement/ListRolePage.vue';
import AddRolePage from '@/pages/Dashboard/Pages/Admin/RoleManagement/AddRolePage.vue';
import EditRolePage from '@/pages/Dashboard/Pages/Admin/RoleManagement/EditRolePage.vue';

// Permission Management
import PermissionManagementPage from '@/pages/Dashboard/Pages/Admin/PermissionManagementPage.vue';
import ListPermissionPage from '@/pages/Dashboard/Pages/Admin/PermissionManagement/ListPermissionPage.vue';
import AddPermissionPage from '@/pages/Dashboard/Pages/Admin/PermissionManagement/AddPermissionPage.vue';
import EditPermissionPage from '@/pages/Dashboard/Pages/Admin/PermissionManagement/EditPermissionPage.vue';

// Permission Access Management
import ListPermissionAccessPage from '@/pages/Dashboard/Pages/Admin/PermissionAccess/ListPermissionAccessPage.vue';
import AddPermissionAccessPage from '@/pages/Dashboard/Pages/Admin/PermissionAccess/AddPermissionAccessPage.vue';

// Approve setup
import ListUniqueApprover from '@/pages/Dashboard/Pages/Admin/ListUnique.vue';

// Private code setup
import PrivatecodePage from '@/pages/Dashboard/Pages/Admin/PrivatecodeManagement/PrivatecodePage.vue';

// Staff role
import ListStaffRolePage from '@/pages/Dashboard/Pages/Admin/UserRole/ListUserRolePage.vue';
import EditStaffRolePage from '@/pages/Dashboard/Pages/Admin/UserRole/EditUserRolePage.vue';

// Payroll Salary
import EditData from '@/pages/Dashboard/Pages/Admin/EditData.vue';
import EditDataflatreat from '@/pages/Dashboard/Pages/Admin/EditDataflatreat.vue';
import SalaryProcessPage from '@/pages/Dashboard/Pages/Payroll/SalaryProcess/SalaryProcessPage.vue';
import SpaProcessPage from '@/pages/Dashboard/Pages/Admin/Flatreat/SpaProcessPage.vue';
import OTflatreatProcessPage from '@/pages/Dashboard/Pages/Admin/Flatreat/OTflatreatProcessPage.vue';
import SalaryPeriod from '@/pages/Dashboard/Pages/Payroll/SalaryPeriod.vue';
import CheckSlipPage from '@/pages/Dashboard/Pages/Payroll/CheckSlipPage.vue';
import SettingLimitTax from '@/pages/Dashboard/Pages/Payroll/SettingLimitTax.vue';
import SettingEmployeeWeekend from '@/pages/Dashboard/Pages/Payroll/SettingEmployeeWeekend.vue';
import SettingEmployeeSSO from '@/pages/Dashboard/Pages/Payroll/SettingEmployeeSSO.vue';
import SettingOT from '@/pages/Dashboard/Pages/Payroll/SettingOT.vue';
import ExportPageAll from '@/pages/Dashboard/Pages/Payroll/ExportPageAll.vue';
import ImportPageAll from '@/pages/Dashboard/Pages/Payroll/ImportPageAll.vue';
import ManagePayrollPage from '@/pages/Dashboard/Pages/Payroll/ManagePayrollPage.vue';
import SettingPriceOT from '@/pages/Dashboard/Pages/Payroll/ot_flat_rate/SettingPriceOT.vue';
import SettingPriceOTspa from '@/pages/Dashboard/Pages/Payroll/ot_flat_rate/SettingPriceOTspa.vue';
import SettingTreatment from '@/pages/Dashboard/Pages/Payroll/ot_flat_rate/SettingTreatment.vue';
import SettingOTFlatRate from '@/pages/Dashboard/Pages/Payroll/ot_flat_rate/SettingOTFlatRate.vue';
import SettingScan from '@/pages/Dashboard/Pages/Payroll/SettingScan.vue';
import ReportRemainleave from '@/pages/Dashboard/Pages/Admin/ReportRemainleave.vue';
//import SelectStaffPage from '@/pages/Dashboard/Pages/Payroll/SalaryProcess/SelectStaffPage.vue';

// Report
import Appv_Salary from '@/pages/Dashboard/Pages/Executive/Appv_Salary.vue';
import Appv_spa from '@/pages/Dashboard/Pages/Executive/Appv_spa.vue';

//Leave
import Leave from '@/pages/Dashboard/Pages/Staff/Leave/Leave.vue';
import LeaveEdit from '@/pages/Dashboard/Pages/Staff/Leave/LeaveEdit.vue';
import LeaveApprovePage from '@/pages/Dashboard/Pages/Staff/Transactions/LeaveSystem/LeaveApprovePage.vue';
// import AddLeavePage from '@/pages/Dashboard/Pages/Staff/Transactions/LeaveSystem/AddLeavePage.vue';

// OT
import OTRequestPage from '@/pages/Dashboard/Pages/Staff/Transactions/OT/OTRequestPage.vue';
import OTApprovePage from '@/pages/Dashboard/Pages/Staff/Transactions/OT/OTApprovePage.vue';

//OT flatrate
import OTRequestCharter from '@/pages/Dashboard/Pages/Staff/Transactions/OTFlatRateSpa/OTRequestCharter.vue';
import SpaRequestCharter from '@/pages/Dashboard/Pages/Staff/Transactions/OTFlatRateSpa/SpaRequestCharter.vue';

// Keepday
// import KeepdayRequestPage from '@/pages/Dashboard/Pages/Staff/Transactions/Keepdays/KeepdayRequestPage.vue';
import KeepdayApprovePage from '@/pages/Dashboard/Pages/Staff/Transactions/Keepdays/KeepdayApprovePage.vue';

// EmphasWorking
import WorkingApprovePage from '@/pages/Dashboard/Pages/Staff/Transactions/Working/WorkingApprovePage.vue';

//approve all
import ApproveAll from '@/pages/Dashboard/Pages/Executive/Appv_All.vue';
import Appv_leavesOT from '@/pages/Dashboard/Pages/Executive/Appv_leavesOT.vue';
import Appv_boatSpa from '@/pages/Dashboard/Pages/Executive/Appv_boatSpa.vue';
import Appv_OT_Over from '@/pages/Dashboard/Pages/Executive/Appv_OT_Over.vue';

import HistoryApprovePage from '@/pages/Dashboard/Pages/Staff/Transactions/HistoryApprovePage.vue';
import Notifications from '@/pages/Dashboard/Components/Notifications.vue';
// import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
// import Register from '@/pages/Dashboard/Pages/Register.vue';

// Components pages
import FormContract from '@/pages/Dashboard/Pages/Admin/Contract/FormContract.vue';
import Form_Contract from '@/pages/Dashboard/Pages/Admin/Contract/Form_Contract.vue';
import AddContract from '@/pages/Dashboard/Pages/Admin/Contract/AddContract.vue';
import EditContract from '@/pages/Dashboard/Pages/Admin/Contract/EditContract.vue';
import SignContract from '@/pages/Dashboard/Pages/SignContract.vue';
import FirstPassword from '@/pages/Dashboard/Pages/FirstPassword.vue';
import FirstPassword_notline from '@/pages/Dashboard/Pages/FirstPassword_notline.vue';
import santhiyaSOS from '@/pages/Dashboard/Pages/SanthiyaSOS/santhiyaSOS.vue';
import list_santhiyaSOS from '@/pages/Dashboard/Pages/SanthiyaSOS/list_santhiyaSOS.vue';
import addSanthiyaSOS from '@/pages/Dashboard/Pages/SanthiyaSOS/addSanthiyaSOS.vue';
import showSanthiyaSOS from '@/pages/Dashboard/Pages/SanthiyaSOS/showSanthiyaSOS.vue';
import EditSanthiyaSOS from '@/pages/Dashboard/Pages/SanthiyaSOS/Edit_SanthiyaSOS.vue';
import createMemo from '@/pages/Dashboard/Pages/SanthiyaSOS/createMemo.vue';
import MemoList from '@/pages/Dashboard/Pages/SanthiyaSOS/MemoList.vue';

// Appeal
import requestAppeal from '@/pages/Dashboard/Pages/Admin/Appeal/requestAppeal.vue';
import listAppeal from '@/pages/Dashboard/Pages/Admin/Appeal/listAppeal.vue';
import viewAppeal from '@/pages/Dashboard/Pages/Admin/Appeal/viewAppeal.vue';

import InformationsSetting from '@/pages/Dashboard/Pages/Staff/Transactions/Information/InformationsSetting.vue';
// import Icons from '@/pages/Dashboard/Components/Icons.vue';
//import Typography from '@/pages/Dashboard/Components/Typography.vue';

// Maps pages
//import FullScreenMap from '@/pages/Dashboard/Maps/FullScreenMap.vue';

//import middleware
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';

// import Admin Reset Password
import Admin_reset_password from '@/pages/Dashboard/Pages/Admin/Admin_reset_password.vue';

let componentStaffMenu = {
  path: '/staff',
  name: 'Staff',
  component: DashboardLayout,
  redirect: '/components/notification',
  children: [
    // {
    //   path: 'notifications',
    //   name: 'Notifications',
    //   components: { default: Notifications },
    //   meta: { middleware: auth },
    // },
    {
      path: 'leaveapprove',
      name: 'อนุมัติวันลา',
      components: { default: LeaveApprovePage },
      meta: { middleware: auth },
    },
    {
      path: 'otapprove',
      name: 'อนุมัติทำงานล่วงเวลา',
      components: { default: OTApprovePage },
      meta: { middleware: auth },
    },
    {
      path: 'keepdayapprove',
      name: 'อนุมัติทำงานวันหยุด',
      components: { default: KeepdayApprovePage },
      meta: { middleware: auth },
    },
    {
      path: 'workingapprovepage',
      name: 'อนุมัติกะการทำงาน',
      components: { default: WorkingApprovePage },
      meta: { middleware: auth },
    },
    {
      path: 'approveleavesOT',
      name: 'อนุมัติลา และ OT',
      components: { default: Appv_leavesOT },
      meta: { middleware: auth },
    },
    {
      path: 'approveboatspa',
      name: 'อนุมัติเที่ยวเรือ และนวดสปา',
      components: { default: Appv_boatSpa },
      meta: { middleware: auth },
    },
    {
      path: 'appvotover',
      name: 'อนุมัติ OT เกิน 3 ชั่วโมง',
      components: { default: Appv_OT_Over },
      meta: { middleware: auth },
    },
    {
      path: 'approveall',
      name: 'การอนุมัติรายการทั้งหมด',
      components: { default: ApproveAll },
      meta: { middleware: auth },
    },
    {
      path: 'historyapprove',
      name: 'ประวัติการอนุมัติ',
      components: { default: HistoryApprovePage },
      meta: { middleware: auth },
    },
    {
      path: 'transaction',
      name: 'Transaction',
      components: { default: Transaction },
      meta: { middleware: auth },
    },
    {
      path: 'transaction/addworkingtime',
      name: 'เพิ่ม กะทำงานพนักงาน',
      components: { default: AddWorkignTimeStaffPage },
      meta: { middleware: auth },
    },
    {
      path: 'transaction/editworkingtime',
      name: 'แก้ไข กะทำงานพนักงาน',
      components: { default: EditWorkignTimeStaffPage },
      meta: { middleware: auth },
    },
    {
      path: 'transaction/calendarworking',
      name: 'ปฏิทินการทำงาน',
      components: { default: CalendatWorkignPage },
      meta: { middleware: auth },
    },
    {
      path: 'leave',
      name: 'บันทึกการลา',
      components: { default: Leave },
      meta: { middleware: auth },
    },
    {
      path: 'otrequest',
      name: 'ขอทำงานล่วงเวลา',
      components: { default: OTRequestPage },
      meta: { middleware: auth },
    },
    {
      path: 'otrequestcharter',
      name: 'OT เหมา',
      components: { default: OTRequestCharter },
      meta: { middleware: auth },
    },
    {
      path: 'sparequestcharter',
      name: 'ค่านวดสปา',
      components: { default: SpaRequestCharter },
      meta: { middleware: auth },
    },
  ],
};

let componentExecutiveMenu = {
  path: '/executive',
  name: 'Executive Manager',
  component: DashboardLayout,
  redirect: '/components/notification',
  children: [
    {
      path: 'salary-report',
      name: 'รายงานเงินเดือน',
      components: { default: Appv_Salary },
      meta: { middleware: auth },
    },
    {
      path: 'flatreatspa-report',
      name: 'รายงาน OT เหมา และค่านวดสปา',
      components: { default: Appv_spa },
      meta: { middleware: auth },
    },
  ],
};

let componentAdminMenu = {
  path: '/admin',
  name: 'Admin',
  component: DashboardLayout,
  redirect: '/components/notification',
  children: [
    {
      path: 'ChangeShiftWorkForManager',
      name: 'กะพนักงาน (ของหัวหน้างาน)',
      components: { default: ChangeShiftWorkForManager },
      meta: { middleware: auth },
    },
    {
      path: 'editdata-flatreat',
      name: 'จัดการข้อมูลค่านวดสปา',
      components: { default: EditDataflatreat },
      meta: { middleware: auth },
    },
    {
      path: 'spa-process',
      name: 'คำนวณค่านวดสปา',
      components: { default: SpaProcessPage },
      meta: { middleware: auth },
    },
    {
      path: 'otflatreat-process',
      name: 'คำนวณ OT เหมา',
      components: { default: OTflatreatProcessPage },
      meta: { middleware: auth },
    },
    {
      path: 'form-contract',
      name: 'ฟอร์มสัญญาจ้าง',
      components: { default: FormContract },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'form_contract',
      name: 'ฟอร์ม_สัญญาจ้าง',
      components: { default: Form_Contract },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'add-contract',
      name: 'เพิ่ม สัญญาจ้าง',
      components: { default: AddContract },
      meta: { middleware: auth },
    },
    {
      path: 'edit-contract/:id',
      name: 'แก้ไข สัญญาจ้าง',
      components: { default: EditContract },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'santhiayaSOS-management',
      name: 'จัดการสัญธิญา SOS',
      components: { default: santhiyaSOS },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'memoList',
      name: 'จัดการ Memo',
      components: { default: MemoList },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'list_santhiayaSOS',
      name: 'list santhiya SOS',
      components: { default: list_santhiyaSOS },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'createMemo',
      name: 'สร้าง Memo',
      nameen: 'Create Memo',
      components: { default: createMemo },
      meta: { middleware: auth },
    },
    {
      path: 'add-santhiya SOS',
      name: 'เพิ่ม Memo santhiya SOS',
      components: { default: addSanthiyaSOS },
      meta: { middleware: auth },
    },
    {
      path: 'show-santhiyaSOS',
      name: 'santhiya SOS',
      components: { default: showSanthiyaSOS },
      meta: { middleware: auth },
    },
    {
      path: 'Edit-santhiya SOS',
      name: 'Edit santhiya SOS',
      components: { default: EditSanthiyaSOS },
      meta: { middleware: auth },
    },
    {
      path: 'viewAppeal',
      name: 'รายการข้อเสนอแนะ / ข้อร้องเรียน',
      components: { default: viewAppeal },
      meta: { middleware: auth },
    },
    {
      path: 'requestAppeal',
      name: 'ข้อเสนอแนะ / ข้อร้องเรียน',
      nameen: 'Suggestion',
      components: { default: requestAppeal },
      meta: { middleware: auth },
    },
    {
      path: 'listAppeal',
      name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
      nameen: 'Suggestion Report',
      components: { default: listAppeal },
      meta: { middleware: auth },
    },
    {
      path: 'InformationsSetting',
      name: 'จัดการข้อปฏิบัติ & แนะนำแผนก',
      nameen: 'Informations Setting',
      components: { default: InformationsSetting },
      meta: { middleware: auth },
    },
    {
      path: 'list-users',
      name: 'จัดการพนักงาน',
      components: { default: ListUserPage },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'edit-user/:id',
      name: 'Edit User',
      components: { default: EditStaffPage },
      meta: { middleware: auth },
      props: true,
    },
    {
      path: 'add-staff',
      name: 'เพิ่ม พนักงาน',
      components: { default: AddStaffPage },
      meta: { middleware: auth },
    },
    {
      path: 'list-workingtime',
      name: 'รายการ กะการทำงาน',
      components: { default: ListWorkingTimePage },
      meta: { middleware: auth },
    },
    {
      path: 'add-workingtime',
      name: 'เพิ่ม กะการทำงาน',
      components: { default: AddWorkingTimePage },
      meta: { middleware: auth },
    },
    {
      path: 'edit-workingtime',
      name: 'แก้ไข กะการทำงาน',
      components: { default: EditWorkingTimePage },
      meta: { middleware: auth },
    },
    {
      path: 'list-weekend',
      name: 'รายการ วันหยุดประจำสัปดาห์',
      components: { default: ListWeekendPage },
      meta: { middleware: auth },
    },
    {
      path: 'add-weekendtime',
      name: 'เพิ่ม วันหยุดประจำสัปดาห์',
      components: { default: AddWeekendPage },
      meta: { middleware: auth },
    },
    {
      path: 'edit-weekend',
      name: 'แก้ไข วันหยุดประจำสัปดาห์',
      components: { default: EditWeekendPage },
      meta: { middleware: auth },
    },
    {
      path: 'list-department',
      name: 'รายการ แผนก',
      components: { default: ListDepartmentPage },
      meta: { middleware: auth },
    },
    {
      path: 'add-department',
      name: 'เพิ่ม แผนก',
      components: { default: AddDepartmentPage },
      meta: { middleware: auth },
    },
    {
      path: 'edit-department',
      name: 'แก้ไข แผนก',
      components: { default: EditDepartmentPage },
      meta: { middleware: auth },
    },
    {
      path: 'list-position',
      name: 'รายการ ตำแหน่ง',
      components: { default: ListPositionPage },
      meta: { middleware: auth },
    },
    {
      path: 'add-position',
      name: 'เพิ่ม ตำแหน่ง',
      components: { default: AddPositionPage },
      meta: { middleware: auth },
    },
    {
      path: 'edit-position',
      name: 'แก้ไข ตำแหน่ง',
      components: { default: EditPositionPage },
      meta: { middleware: auth },
    },
    {
      path: 'permission-management-new',
      name: 'Permission Management',
      components: { default: PermissionManagementPage },
      meta: { middleware: auth },
    },
    {
      path: 'role-management/list-roles',
      name: 'List Roles',
      components: { default: ListRolePage },
      meta: { middleware: auth },
    },
    {
      path: 'role-management/add-role',
      name: 'Add Role',
      components: { default: AddRolePage },
      meta: { middleware: auth },
    },
    {
      path: 'role-management/edit-role/:id',
      name: 'Edit Role',
      components: { default: EditRolePage },
      meta: { middleware: auth },
    },
    {
      path: 'permission-management/list-permissions',
      name: 'List Permissions',
      components: { default: ListPermissionPage },
      meta: { middleware: auth },
    },
    {
      path: 'permission-management/add-permission',
      name: 'Add Permission',
      components: { default: AddPermissionPage },
      meta: { middleware: auth },
    },
    {
      path: 'permission-management/edit-permission/:id',
      name: 'Edit Permission',
      components: { default: EditPermissionPage },
      meta: { middleware: auth },
    },
    {
      path: 'permission-access/list-permissions-access/:id/type/:type_name',
      name: 'List Permissions Access',
      components: { default: ListPermissionAccessPage },
      meta: { middleware: auth },
    },
    {
      path: 'permission-access/add-permissions-access/:id/type/:type_name',
      name: 'add Permissions Access',
      components: { default: AddPermissionAccessPage },
      meta: { middleware: auth },
    },
    {
      path: 'approve-setup',
      name: 'ตั้งค่าการอนุมัติ',
      components: { default: ListUniqueApprover },
      meta: { middleware: auth },
    },
    {
      path: 'privatecode-setup',
      name: 'จัดการ Privatecode',
      components: { default: PrivatecodePage },
      meta: { middleware: auth },
    },
    {
      path: 'staff-role/list-staff-role/:id/:branch',
      name: 'จัดการสิทธิ์ User',
      components: { default: ListStaffRolePage },
      meta: { middleware: auth },
    },
    {
      path: 'staff-role/edit-staff-role/:id/',
      name: 'แก้ไขสิทธิ์ User',
      components: { default: EditStaffRolePage },
      meta: { middleware: auth },
    },
    {
      path: 'admin-reset-password',
      name: 'Reset รหัสผ่าน',
      components: { default: Admin_reset_password },
      meta: { middleware: auth },
    },
  ],
};

let componentPayrollMenu = {
  path: '/payroll',
  name: 'Payroll',
  component: DashboardLayout,
  redirect: '/components/notification',
  children: [
    // {
    //   path: 'salary-period',
    //   name: 'ตั้งค่างวดของเงินเดือน',
    //   components: { default: SalaryPeriod },
    //   meta: { middleware: auth },
    // },
    {
      path: 'reportremainleave',
      name: 'จัดการวันลาคงเหลือ',
      components: { default: ReportRemainleave },
      meta: { middleware: auth },
    },
    {
      path: 'editdata',
      name: 'จัดการข้อมูล',
      components: { default: EditData },
      meta: { middleware: auth },
    },
    {
      path: 'exportpage',
      name: 'ดึงข้อมูล',
      components: { default: ExportPageAll },
      meta: { middleware: auth },
    },
    {
      path: 'importpage',
      name: 'นำเข้าข้อมูล',
      components: { default: ImportPageAll },
      meta: { middleware: auth },
    },
    {
      path: 'checkslippage',
      name: 'ตรวจสอบสลิปเงินเดือน',
      components: { default: CheckSlipPage },
      meta: { middleware: auth },
    },
    {
      path: 'managepayroll',
      name: 'จัดการสำหรับ Payroll',
      components: { default: ManagePayrollPage },
      meta: { middleware: auth },
    },
    {
      path: 'setting-staff-weekend',
      name: 'ตั้งค่าวันหยุดประจำสัปดาห์',
      components: { default: SettingEmployeeWeekend },
      meta: { middleware: auth },
    },
    {
      path: 'setting-staff-sso',
      name: 'ตั้งค่าประกันสังคม',
      components: { default: SettingEmployeeSSO },
      meta: { middleware: auth },
    },
    {
      path: 'setting-limittax',
      name: 'ตั้งค่าการคำนวณภาษี',
      components: { default: SettingLimitTax },
      meta: { middleware: auth },
    },
    {
      path: 'setting-ot',
      name: 'ตั้งค่า OT',
      components: { default: SettingOT },
      meta: { middleware: auth },
    },
    {
      path: 'settingPrice-ot',
      name: 'ตั้งค่าอัตราเที่ยวเรือ',
      components: { default: SettingPriceOT },
      meta: { middleware: auth },
    },
    {
      path: 'settingPrice-ot-spa',
      name: 'ตั้งค่าอัตราค่านวดสปา',
      components: { default: SettingPriceOTspa },
      meta: { middleware: auth },
    },
    {
      path: 'SettingTreatment',
      name: 'ตั้งค่าทรีทเม้นท์',
      components: { default: SettingTreatment },
      meta: { middleware: auth },
    },
    {
      path: 'SettingOTFlatRate',
      name: 'ตั้งค่า OT เหมาและค่านวดสปา',
      components: { default: SettingOTFlatRate },
      meta: { middleware: auth },
    },
    {
      path: 'settingScan',
      name: 'ตั้งค่าผูกรหัสสแกนลายนิ้วมือ',
      components: { default: SettingScan },
      meta: { middleware: auth },
    },
    {
      path: 'salary-process',
      name: 'คำนวณเงินเดือน',
      components: { default: SalaryProcessPage },
      meta: { middleware: auth },
    },
    {
      path: 'salary-report',
      name: 'Salary Report',
      components: { default: Appv_Salary },
      meta: { middleware: auth },
    },
    {
      path: 'flatreatspa-report',
      name: 'FlatreatSpa-Report',
      components: { default: Appv_spa },
      meta: { middleware: auth },
    },
  ],
};

/**
 * menu for user profile
 */
let userMenu = {
  path: '/user',
  name: 'User',
  component: DashboardLayout,
  children: [
    {
      path: 'user-profile',
      name: 'User Profile',
      components: { default: UserProfile },
      meta: { middleware: auth },
    },
    {
      path: 'reset-password',
      name: 'Reset Password',
      components: { default: ResetPassword },
      meta: { middleware: auth },
    },
    // {
    //   path: "user-management/list-users",
    //   name: "List Users",
    //   components: { default: ListUserPage },
    //   meta: { middleware: auth }
    // }
  ],
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { middleware: guest },
    },
    {
      path: '/firstpassword/:id',
      name: 'Register',
      component: FirstPassword,
    },
    {
      path: '/firstpassword-notline',
      name: 'Register-Notline',
      component: FirstPassword_notline,
    },
    {
      path: '/sign-contract/:id',
      name: 'เซ็นยืนยันสัญญาจ้าง',
      component: SignContract,
      props: true,
    },
  ],
};

const routes = [
  {
    path: '/',
    redirect: '/user/user-profile',
    name: 'Home',
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: { middleware: auth },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: { middleware: auth },
      },
    ],
  },
  userMenu,
  authPages,
  componentStaffMenu,
  componentExecutiveMenu,
  componentAdminMenu,
  componentPayrollMenu,
];

export default routes;
